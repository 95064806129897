import { RouteRecordRaw } from 'vue-router';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { daAuthClient, tracker } from '@api-client';
import ErrorPage from '@deployment-client/ErrorPage.vue';

const Sidebar = () => import('@/pages/Deployment/DeploymentSidebar.vue');
const EmptyContainer = () => import('@/views/EmptyContainer.vue');

const TenantPickerPage = () => import('@/pages/TenantPicker');

export const route: RouteRecordRaw = {
    path: '/deployment',
    components: {
        sidebar: Sidebar,
        default: EmptyContainer
    },
    props: {
        sidebar: {
            expandedByDefault: false
        },
        default: (location) => ({
            routerViewKey: location.params.tenant as string | undefined
        })
    },
    children: [
        {
            name: 'logout',
            path: 'logout',
            redirect: () => {
                daAuthClient.logout();
                return '/deployment/login';
            }
        },
        {
            path: 'list',
            name: 'list',
            component: TenantPickerPage,
            props: {
                title: 'Deployment',
                app: 'Deployment'
            },
            beforeEnter: redirectToValidRouteForUser({ appName: 'Deployment' })
        },
        {
            path: '404',
            name: '404',
            component: ErrorPage,
            props: {
                errorTitle: '404 Not Found', details: `
                We couldn't find the page you were looking for. Please contact
                support for more information.
            ` },
            meta: { noAuth: true }
        },
        {
            path: '403',
            name: '403',
            component: ErrorPage,
            props: {
                errorTitle: '403 Forbidden', details: `
                You don't have access to the page you tried to load. Please contact
                support for more information.
            ` },
            meta: { noAuth: true }
        },
        {
            path: '500',
            name: '500',
            component: ErrorPage,
            props: {
                errorTitle: '500 Internal Server Error', details: `
                The server encountered an error. Please contact support
                for more information.
            ` },
            meta: { noAuth: true }
        },
        {
            path: 'error',
            name: 'dataError',
            component: ErrorPage,
            props: {
                errorTitle: 'Data Error', details: `
                There was an error with the data you tried to load. Please contact
                support for more information.
            ` },
            meta: { noAuth: true }
        },
        {
            path: '',
            redirect: { name: 'list' }
        },
        {
            // Any route that doesn't start with /login. Ideally, we'd match everything but _exactly_
            // login, but Vue wraps this regex in some of its own syntax which breaks a naive "everything but
            // login" regex.
            path: ':tenant((?!login\\)[^/]+)',
            name: 'DeploymentApp',
            component: () => import('@/pages/Deployment/DeploymentApp.vue'),
            beforeEnter(to, from, next) {
                tracker.registerSessionProps({
                    database: to.params.tenant
                });
                next();
            }
        }
    ],
    meta: { title: 'DHE Deployment Analyzer' }
};
