import _ from 'lodash';

export type ToggleDefinition = {
    name: string;
    description: string;
}
export type ToggleInfo = {
    name: string;
    description: string;
    value: boolean;
    defaultValue: boolean;
};

export type ToggleSetDefinition<ToggleKey extends string> = {
    label: string,
    description: string,
    toggles: ToggleKey[],
}
export const FEATURE_TOGGLES_LOCAL_STORAGE_KEY = 'DHE:FeatureToggleOverrides';

export class FeatureToggles<ToggleKey extends string, ToggleSet extends string> {
    toggleDefinitions: Record<ToggleKey, ToggleDefinition>;
    toggleSets: Record<ToggleSet, ToggleSetDefinition<ToggleKey>>;
    defaultToggleValues: Record<ToggleKey, boolean>;
    toggleValues: Record<ToggleKey, boolean>;

    /**
     * Initialize feature toggles, applying user overrides from localStorage
     * (if localStorage exists) as necessary.
     */
    constructor(
        toggleDefinitionsById: Record<ToggleKey, ToggleDefinition>,
        defaultToggleValues: Record<ToggleKey, boolean>,
        toggleSets: Record<ToggleSet, ToggleSetDefinition<ToggleKey>>
    ) {
        this.toggleDefinitions = toggleDefinitionsById;
        this.defaultToggleValues = defaultToggleValues;
        this.toggleSets = toggleSets;

        this.toggleValues = this.getToggleValues();
    }

    featureIsEnabled(id: ToggleKey): boolean {
        return this.toggleValues[id];
    }
    getFeatureToggles(): Record<ToggleKey, boolean> {
        return this.toggleValues;
    }
    getFeatureTogglesInfo(): Record<ToggleKey, ToggleInfo> {
        const result: Record<ToggleKey, ToggleInfo> = {} as any;
        const keys = Object.keys(this.toggleDefinitions) as ToggleKey[];
        for (const key of keys) {
            result[key] = {
                ...this.toggleDefinitions[key],
                value: this.toggleValues[key],
                defaultValue: this.defaultToggleValues[key]
            };
        }
        return result;
    }

    /**
     * Save the specified user overrides. This does not affect currently active
     * toggles, so app reload is required for these to take effect.
     */
    saveUserFeatureToggleOverrides(overrides: Record<ToggleKey, boolean>) {
        const newOverrides = this.getOverridesThatArentDefaults(overrides);
        this.persistOverridesToLocalStorage(newOverrides);
    }

    applyToggleGroup(toggleGroup: ToggleSet, enable = true) {
        this.defaultToggleValues = applyDefaultFeatureToggleValues(
            this.defaultToggleValues,
            this.toggleDefinitions,
            this.toggleSets,
            [toggleGroup],
            enable
        );
        this.toggleValues = this.getToggleValues();
    }

    getToggleSets() {
        return this.toggleSets;
    }

    private getToggleValues() {
        if (globalThis.localStorage) {
            const userOverrides = this.getSanitizedLocalStorageOverrides();
            const newUserOverrides = this.getOverridesThatArentDefaults(userOverrides);

            // This removes overrides that no longer correspond to toggles, but *not* those that
            // are defaults, since the defaults are allowed to change after this class is first
            // instantiated. Note that overrides that match defaults are removed on calls to
            // saveUserFeatureToggleOverrides
            this.persistOverridesToLocalStorage(userOverrides);
            return {
                ...this.defaultToggleValues,
                ...newUserOverrides,
            };
        }
        return this.defaultToggleValues;
    }

    private getOverridesThatArentDefaults(
        overrides: Partial<Record<ToggleKey, boolean>>
    ): Partial<Record<ToggleKey, boolean>> {
        const newOverrides: Partial<Record<ToggleKey, boolean>> = {};

        const keys = Object.keys(this.defaultToggleValues) as ToggleKey[];
        keys.forEach((key) => {
            const value = this.defaultToggleValues[key];
            const overrideValue = overrides[key];
            if (overrideValue !== undefined && overrideValue !== value) {
                newOverrides[key] = overrideValue;
            }
        });

        return newOverrides;
    }

    /**
     * Retrieve user-specified toggle overrides from localStorage and return
     * an object that only has boolean values (i.e. filter out any garbage that
     * a user / process may have fiddled with)
     */
    private getSanitizedLocalStorageOverrides(): Partial<Record<ToggleKey, boolean>> {
        let localStorageOverrides: any;

        try {
            localStorageOverrides = JSON.parse(localStorage.getItem(FEATURE_TOGGLES_LOCAL_STORAGE_KEY) || '{}');
        } catch (e: unknown) {
            localStorageOverrides = {};
            console.error(`Error loading overridden feature toggles: ${JSON.stringify(e)}`);
        }

        const sanitizedValues: Partial<Record<ToggleKey, boolean>> = {};

        if (localStorageOverrides !== null && typeof localStorageOverrides === 'object') {
            const keys = Object.keys(this.defaultToggleValues) as ToggleKey[];
            keys.forEach((key) => {
                const value = localStorageOverrides[key];
                if (typeof value === 'boolean') {
                    sanitizedValues[key] = value;
                }
            });
        }

        return sanitizedValues;
    }

    private persistOverridesToLocalStorage(overrides: Partial<Record<ToggleKey, boolean>>) {
        try {
            if (Object.entries(overrides).length > 0) {
                localStorage.setItem(FEATURE_TOGGLES_LOCAL_STORAGE_KEY, JSON.stringify(overrides));
            } else {
                localStorage.removeItem(FEATURE_TOGGLES_LOCAL_STORAGE_KEY);
            }
        } catch (e: unknown) {
            console.error(`Error saving overridden feature toggles: ${JSON.stringify(e)}`);
        }
    }
}

/**
 * Initialize feature toggles, applying user overrides from localStorage
 * (if localStorage exists) as necessary.
 *
 * This must be called prior to any other functions in this file.
 */
export function initFeatureToggles<ToggleKey extends string, ToggleSet extends string>(
    toggleDefinitions: Record<ToggleKey, any>,
    defaultFeatureTogglesByToggleSet: Record<ToggleSet, ToggleSetDefinition<ToggleKey>>,
    toggleGroups: ToggleSet[]
) {
    const defaultToggles = applyDefaultFeatureToggleValues(
        {},
        toggleDefinitions,
        defaultFeatureTogglesByToggleSet,
        toggleGroups
    );
    return new FeatureToggles<ToggleKey, ToggleSet>(toggleDefinitions, defaultToggles, defaultFeatureTogglesByToggleSet);
}

export function applyDefaultFeatureToggleValues<ToggleKey extends string, ToggleSet extends string>(
    currentToggles: Partial<Record<ToggleKey, boolean>>,
    toggleDefinitions: Record<ToggleKey, any>,
    defaultFeatureTogglesByToggleSet: Record<ToggleSet, ToggleSetDefinition<ToggleKey>>,
    toggleGroups: ToggleSet[],
    enable = true
) {
    const allDisabled = _.mapValues(toggleDefinitions, () => false) as Record<ToggleKey, boolean>;
    const toggles = {
        ...allDisabled,
        ...currentToggles
    } as Record<ToggleKey, boolean>;

    for (const group of toggleGroups) {
        defaultFeatureTogglesByToggleSet[group].toggles.forEach(toggle => {
            toggles[toggle] = enable;
        });
    }

    return toggles;
}
