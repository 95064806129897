import { ToggleGroup } from 'common';
import { computed, watch } from 'vue';
import { useFeatureToggles } from '@/composables/featureToggles/useFeatureToggles';
import { useUserStore } from '@/stores/UserStore';

const ToggleGroupsByCognitoGroupName: Record<string, ToggleGroup> = {
    'org:darkhorse': 'internalBeta',
    'feature:unit-capabilities': 'unitCapabilitiesExternalBeta'
};

export function useUpdateFeatureTogglesForUser() {
    const { applyToggleGroup } = useFeatureToggles();

    const userStore = useUserStore();
    const userGroups = computed(() => userStore.currentUser?.groups ?? []);

    watch(userGroups, (newGroups, oldGroups) => {
        for (const group of newGroups) {
            const toggleGroup = ToggleGroupsByCognitoGroupName[group];

            if (toggleGroup) {
                applyToggleGroup(toggleGroup);
            }
        }
    }, { immediate: true });

    return {};
}
