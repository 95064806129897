import * as t from 'io-ts';
import { createDynamicQuery, createParseFunction } from '@api-client/helpers/types';
import type { MetricQueryFilter } from '../client';

const metricByDateBinResult = t.type({
    api: t.type({
        metricSetByDateBin: t.type({
            query: t.union([t.string, t.null]),
            result: t.array(t.type({
                bin: t.string,
                value: t.union([t.number, t.null])
            }))
        })
    })
});

export const metricByDateBinQuery = createDynamicQuery(
    (variables?: {
        tenantId: string,
        groupingMetrics: string[],
        metric: string,
        responseGroup: string,
        isoStartDate: string,
        isoEndDate: string,
        subInterval: { unit: string; count: number },
        filters: MetricQueryFilter[],
        targetFilters: MetricQueryFilter[],
    }) => {
        const groupingMetrics = variables!.groupingMetrics;
        const queryVariables = variables!;

        const query = `query getMetricByDateBin(
            $tenantId: String!
            $metric: String!,
            $groupingMetrics: [String!]!,
            $responseGroup: String!,
            $isoStartDate: String!,
            $isoEndDate: String!,
            $subInterval: MetricInterval!,
            $filters: [HqMetricFilter!],
            $targetFilters: [HqMetricFilter!]
        ) {
            api: getTenantApi(name:$tenantId) {
                metricSetByDateBin(
                    groupingMetrics: $groupingMetrics,
                    responseGroup: $responseGroup,
                    isoStartDate: $isoStartDate,
                    isoEndDate: $isoEndDate,
                    subInterval: $subInterval,
                    filters: $filters,
                    targetFilters: $targetFilters
                ) {
                    query
                    result {
                        bin
                        value(metric: $metric, filters: $targetFilters)
                        ${groupingMetrics.map((metric) => `${metric}: group(metric:"${metric}")`).join('\n')}
                    }
                }
            }
        }`;
        return {
            query,
            queryVariables
        };
    },
    createParseFunction(metricByDateBinResult)
);
