/**
 * From an array, create an array of the contiguous groups from that array.
 *
 * A contiguous group is a set of consescutive items in the array that have
 * the same value for some property
 *
 *
 * @param arr A single array to group
 * @param groupFn A callback that returns the value to group by
 */
export function createContiguousGroups<Item, GroupType>(
    arr: Item[],
    groupFn: (el: Item) => GroupType
): {value: GroupType; items: Item[]}[] {
    const groups: {value: GroupType; items: Item[]}[] = [];

    arr.forEach(item => {
        const firstGroup = groups[groups.length - 1];
        if (firstGroup && firstGroup.value === groupFn(item)) {
            firstGroup.items.push(item);
        } else {
            groups.push({
                value: groupFn(item),
                items: [item]
            });
        }
    });

    return groups;
}
