import { RouteRecordRaw } from 'vue-router';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';

const Sidebar = () => import('@/views/Sidebar');
const EmptyContainer = () => import('@/views/EmptyContainer.vue');
const RiskRootComponent = () => import('@/pages/Risk').then(c => c.Risk);
const TenantPickerPage = () => import('@/pages/TenantPicker');

export const route: RouteRecordRaw = {
    name: 'Risk',
    path: '/risk',
    meta: { title: 'Risk Assessment' },
    components: {
        sidebar: Sidebar,
        default: EmptyContainer
    },
    props: {
        sidebar: {
            expandedByDefault: false,
            app: 'Risk'
        }
    },
    children: [{
        name: 'RiskTenantPicker',
        path: '',
        component: TenantPickerPage,
        props: {
            title: 'Risk Assessment',
            app: 'Risk',
        },
        beforeEnter: redirectToValidRouteForUser({ appName: 'Risk' })
    }, {
        name: 'RiskApp',
        path: ':tenant',
        component: RiskRootComponent,
        meta: { title: 'Risk Assessment' }
    }]
};
