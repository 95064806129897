<template>
    <div class="flex flex-col">
        <label
            v-for="option in options"
            :key="option.id"
            class="flex gap-1 items-center"
            :for="`${option.id}-radio-button`"
            style="margin:0"
        >
            <input
                :id="`${option.id}-radio-button`"
                :checked="option.id === modelValue"
                name="surrounding-area"
                style="margin:0"
                type="radio"
                :value="option.id"
                @change="onChange"
            >
            <span class="font-supermolot font-normal text-base">
                {{ option.label }}
            </span>
        </label>
    </div>
</template>

<script setup lang="ts">
defineProps<{
    options: { id: string; label: string }[];
    modelValue: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
}>();

function onChange(event: Event) {
    const el = event.target as HTMLInputElement;
    emit('update:modelValue', el.value);
}
</script>
