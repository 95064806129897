import { toValue } from 'vue';
import { env } from '@api-client/envVars';
import { EmptySupportChat, FrontSupportChat, SupportChat } from '@api-client/support';
import { useApiClient } from './useApiClient';

let support: SupportChat | undefined;

export function useSupportChat(): SupportChat {
    if (!support) {
        const rootApiClient = toValue(useApiClient().apiClient);
        support = (
            env.FRONT_CHAT_ID
            && (window as any).FrontChat
        )
            ? new FrontSupportChat(rootApiClient)
            : new EmptySupportChat();

        rootApiClient.Auth.currentUser.then(async user => {
            await user.freshAuth;
            support?.initAndIdentifyUser();
        });
    }

    return support;
}
