import * as t from 'io-ts';

export const polygonGeometry = t.type({
    type: t.literal('Polygon'),
    coordinates: t.array(
        t.array(
            t.tuple(
                [t.number, t.number]
            )
        )
    )
});
export const multiPolygonGeometry = t.type({
    type: t.literal('MultiPolygon'),
    coordinates: t.array(
        t.array(
            t.array(
                t.tuple(
                    [t.number, t.number]
                )
            )
        )
    )
});

export const featureCollection = t.type({
    type: t.literal('FeatureCollection'),
    features: t.array(t.type({
        type: t.literal('Feature'),
        geometry: t.union([polygonGeometry, multiPolygonGeometry]),
        properties: t.type({
            id: t.number,
            name: t.string
        })
    }))
});
