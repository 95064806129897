import type { Map } from 'mapbox-gl';

/**
 * A class that represents a custom Mapbox GL button control.
 */
export class MapboxGenericButtonControl {
    readonly className: string;
    readonly title: string;
    readonly renderContent: (button: HTMLButtonElement) => void;
    readonly eventHandler: () => any;

    private container?: HTMLElement;
    private map?: Map;

    constructor(
        className: string,
        title: string,
        renderContent: (button: HTMLButtonElement) => void,
        eventHandler: () => any
    ) {
        this.className = className;
        this.title = title;
        this.renderContent = renderContent;
        this.eventHandler = eventHandler;
    }

    onAdd(map: Map) {
        this.map = map;

        this.container = document.createElement('div');
        this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';

        const button = document.createElement('button');
        this.renderContent(button);

        button.className = `mapboxgl-ctrl-icon ${this.className}`;
        button.type = 'button';
        button.title = this.title;
        button.onclick = this.eventHandler;

        this.container.appendChild(button);

        return this.container;
    }

    onRemove() {
        if (this.container && this.container.parentNode) {
            this.container.parentNode.removeChild(this.container);
        }
        this.map = undefined;
    }
}

/*
 * A simpler version of the above class for adding an icon button
 */
export class MapboxButtonControl extends MapboxGenericButtonControl {
    constructor(
        className: string,
        title: string,
        iconPath: string,
        eventHandler: () => any,
        renderContent?: (button: HTMLButtonElement) => void
    ) {
        super(
            className,
            title,
            renderContent ?? ((button: HTMLButtonElement) => {
                const icon = document.createElement('img');
                button.appendChild(icon);

                icon.style.display = 'flex';
                icon.style.justifyContent = 'center';
                icon.style.alignItems = 'center';
                icon.style.width = '100%';
                icon.style.height = '18px';
                icon.src = iconPath;
            }),
            eventHandler
        );
    }
}
