import type { FeatureCollection, Geometry, Polygon } from 'geojson';

export const EMPTY_GEOJSON_DATA = {
    crs: {
        propertes: {
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        },
        type: 'name'
    },
    type: 'FeatureCollection',
    features: []
};

export const EMPTY_GEOJSON_SOURCE = {
    type: 'geojson',
    data: EMPTY_GEOJSON_DATA
};

export const EMPTY_GEOJSON: FeatureCollection<Geometry> = {
    type: 'FeatureCollection',
    features: []
};

export const EMPTY_POLYGON: Polygon = {
    type: 'Polygon',
    coordinates: []
};
