import {
    CookieStorage,
    ICognitoStorage
} from 'amazon-cognito-identity-js';
import { env } from '@api-client/envVars';

export function getStorage(options?: {
    allowCookieStorage?: boolean;
}): ICognitoStorage | undefined {
    const allowCookieStorage = options?.allowCookieStorage ?? true;

    const cookieStorage = getCookieStorage();

    // If cookie storage exists, what we do with it depends on the allowCookieStorage option
    if (cookieStorage) {
        // If we're allowed to use cookie storage, clean it up and use it
        if (allowCookieStorage) {
            cleanupStorage(cookieStorage);
            return cookieStorage;
        }

        // If not, clean it up fully, including deleting the current client's keys
        cleanupStorage(cookieStorage, { keepCurrentClientKeys: false });
    }

    // If we can't use cookieStorage for any reason, the default behaviour is localStorage
    // We have to explicitly pass localStorage to cleanupStorage to make sure it's properly cleaned up,
    // but by returning undefined, amazon-cognito-identity-js will use localStorage by default.
    cleanupStorage(window.localStorage);
    return undefined;
}

function getCookieStorage() {
    const cookieStorageDomain = window.location?.hostname?.endsWith('darkhorse.app')
        ? 'darkhorse.app'
        : undefined;

    let cookieStorage: CookieStorage | undefined;

    if (cookieStorageDomain) {
        cookieStorage = new CookieStorage({
            domain: cookieStorageDomain,
            secure: true,
            sameSite: 'strict'
        });
    } else if (window.location.hostname === 'localhost') {
        cookieStorage = new CookieStorage({
            sameSite: 'strict'
        });
    }

    return cookieStorage;
}

/**
 * Clean up a Storage object for use by Cognito. Removes Cognito keys belonging
 * to old user pool clients.
 *
 * The core reason for doing so is that having multiple user pools in CookieStorage
 * dramatically increases HTTP header size, which can exceed header size limits.
 * We've seen this happen with Cloudfront specifically:
 *
 * https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/cloudfront-limits.html#limits-custom-headers
 *
 * @param storage A storage object
 * @param options.keepCurrentClientKeys (optional) If false, will delete all Cognito keys. If true, keep keys for the current client ID
 */
function cleanupStorage(storage: ICognitoStorage | Storage, options?: {
    keepCurrentClientKeys?: boolean;
}) {
    const keysToDelete: string[] = [];

    forEachStorageKey(storage, key => {
        if (checkStorageKeyForDeletion(key, options)) {
            keysToDelete.push(key);
        }
    });

    keysToDelete.forEach(key => storage.removeItem(key));
}

/**
 * Check storage keys for deletion. A storage key should be deleted if it
 * belongs to Cognito, but not from our current user pool client
 *
 * @param key A key to an item in storage
 * @param options.keepCurrentClientKeys (optional) If false, will delete all Cognito keys. If true, keep keys for the current client ID
 * @returns True if it should be deleted, false otherwise
 */
function checkStorageKeyForDeletion(key: string, options?: { keepCurrentClientKeys?: boolean }): boolean {
    const keepCurrentClientKeys = options?.keepCurrentClientKeys ?? true;

    const cognitoKeyPrefx = 'CognitoIdentityServiceProvider';
    const clientId = env.COGNITOCLIENTID;
    if (!clientId) {
        // If we've misconfigured somehow, and don't know what our cognito
        // client id is, we can't confidently delete _other_ clients' cookies
        return false;
    }

    if (keepCurrentClientKeys) {
        return key.includes(cognitoKeyPrefx)
        && !key.includes(clientId);
    }
    return key.includes(cognitoKeyPrefx);
}

/**
 * Iterate over all of the keys in a Storage object
 *
 * Handles both the native web Storage interface and the cognito
 * ICognitoStorage interface. Of note: ICognitoStorage doesn't provide
 * some of the properties of Storage, but CookieStorage is the only
 * concrete implementation provided by the library so we interact directly
 * with document.cookie
 *
 * @param storage A storage object
 * @param cb A callback to call with each key in storage
 */
function forEachStorageKey(storage: ICognitoStorage | Storage, cb: (a: string) => any) {
    if ('length' in storage) {
        for (let i = 0; i < storage.length; ++i) {
            const key = storage.key(i);
            if (key) {
                cb(key);
            }
        }
    } else if (storage instanceof CookieStorage) {
        const keys = document.cookie
            .split(';')
            .map(cookie => cookie.trim().split('=')[0])
            .map(cookie => decodeURIComponent(cookie));

        keys.forEach(key => cb(key));
    }
}
