import { lateResolvePromise } from 'common';
import _ from 'lodash';
import { DeepReadonly, Ref, computed, readonly, ref, watch, watchEffect } from 'vue';

export type QueryObserverState<Result> = {
    result?: Result
    errors?: unknown[];
    isLoading: boolean;
};
export type QueryObserverStateRefs<Result> = {
    result: Ref<Result | undefined>;
    errors: Ref<unknown[] | undefined>;
    isLoading: Ref<boolean>;
}

/**
 * Convert a single ref containing a QueryObserverState into separate refs for each key in the state.
 *
 * The single ref is easier to work with while implementing some of these utilities, but the separate
 * refs are a nicer interface to use in components.
 *
 * @param state A ref containing a QueryObserverState
 * @returns Separate refs for each key in the QueryObserverState
 */
export function toSeparateRefs<T>(state: Ref<QueryObserverState<T>>): QueryObserverStateRefs<T> {
    return {
        result: computed(() => state.value.result),
        errors: computed(() => state.value.errors),
        isLoading: computed(() => state.value.isLoading)
    };
}

/**
 * Convert a set of refs for each key in a QueryObserverState into a single ref.
 *
 * The single ref is easier to work with while implementing some of these utilities, but the separate
 * refs are a nicer interface to use in components.
 *
 * @param state Separate refs for each key in a QueryObserverState
 * @returns A single ref containing the QueryObserverState
 */
export function toCombinedRefs<T>(state: QueryObserverStateRefs<T>): Ref<QueryObserverState<T>> {
    return computed(() => ({
        result: state.result.value,
        errors: state.errors.value,
        isLoading: state.isLoading.value
    }));
}

export function waitUntilResult<T>(query: QueryObserverStateRefs<T>): Promise<T> {
    const p = lateResolvePromise<T>();

    watchEffect(() => {
        const {
            result,
            errors
        } = query;

        if (result.value) {
            p.resolve(result.value);
        }
        if (errors.value) {
            p.reject(new Error(errors.value.join('\n')));
        }
    });

    return p;
}

/**
 * Takes a ref that may be undefined and returns a ref that's always defined and always
 * a promise.
 *
 * We use this primarily for an async process that might not be able to start immediately. We
 * want dependent functionality to be able to await a promise that will resolve with the first result.
 */
export function useImmediatelyDefinedPromise<T>(rawRef: Ref<T | Promise<T> | undefined>): Readonly<Ref<Promise<DeepReadonly<T>>>> {
    const initialPromise = lateResolvePromise<T>();
    let initialPromiseHasResolved = false;
    const internalRef = ref<Promise<T>>(initialPromise);

    watch(() => rawRef.value, (value) => {
        if (value) {
            if (!initialPromiseHasResolved) {
                initialPromise.resolve(value);
                initialPromiseHasResolved = true;
            } else {
                internalRef.value = Promise.resolve(value);
            }
        }
    }, { immediate: true, flush: 'sync' });

    return readonly(internalRef);
}

export function useCombineQueries<T extends QueryObserverStateRefs<any>[]>(
    ...queries: T
): QueryObserverStateRefs<{ [K in keyof T]: T[K]['result']['value'] }> {
    const queryResults = computed(() => {
        if (_.some(queries, q => q.isLoading.value)) {
            return {
                isLoading: true
            };
        }
        const queriesWithError = queries.filter(q => q.errors.value !== undefined);
        const errors = queriesWithError.length > 0
            ? queriesWithError.flatMap(q => q.errors.value ?? [])
            : undefined;

        const results: any = queries.map(q => q.result.value);

        return {
            result: results,
            errors,
            isLoading: false
        };
    });

    return toSeparateRefs(queryResults);
}

export function useTransformQueryResult<Input, Output>(
    input: QueryObserverStateRefs<Input>,
    transformer: (input: Input) => Output
): QueryObserverStateRefs<Output> {
    const combinedInput = toCombinedRefs(input);
    const combinedOutput = computed(() => {
        const {
            result,
            ...status
        } = combinedInput.value;

        if (result) {
            try {
                return {
                    ...status,
                    result: transformer(result)
                };
            } catch (err) {
                return {
                    isLoading: false,
                    errors: [err]
                };
            }
        }
        return status;
    });

    return toSeparateRefs(combinedOutput);
}

export function useThrowQueryErrors<T>(query: Ref<QueryObserverState<T>>) {
    watch(query, (status) => {
        if (status.errors) {
            throw new Error(status.errors.join('\n'));
        }
    });
}
