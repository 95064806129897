<template>
    <div class="button-group whitespace-nowrap">
        <div
            v-for="(subgroup, i) in optionSubgroups"
            :key="i"
            class="subgroup inline-block rounded-md"
            :class="{
                disabled: subgroup.disabled
            }"
        >
            <button
                v-for="option in subgroup.options"
                :key="option.value"
                class="uppercase text-xs tracking-widest
                text-dhagray-500 dark:text-dhagray-200 p-2 rounded-md cursor-pointer
                bg-dhagray-100 dark:bg-dhablue-500
                border border-solid border-dhagray-100 dark:border-dhablue-500"
                :class="{
                    selected: option.value === selected,
                    disabled: option.disabled,
                    [fontClass]: true,
                }"
                :title="option.description"
                @click="onOptionClicked(option)"
            >
                {{ option.label }}
            </button>
        </div>
        <div
            v-if="label"
            class="uppercase text-xs tracking-widest
            py-1 px-2 text-dhagray-200"
            :class="fontClass"
        >
            {{ label }}
        </div>
    </div>
</template>

<style lang="postcss" scoped>
button.des-button-group-look-here {
    @apply bg-black;
}
button {
    @apply min-w-12 mobile:min-w-8;
    transition: background-color 150ms ease;
}
button:hover {
    @apply bg-dhagray-200 dark:bg-dhablue-700;
}
button.selected {
    @apply border-highlight-gold bg-white dark:bg-dhablue-700;
}
button.disabled {
    @apply pointer-events-none bg-transparent border-transparent
        text-dhagray-200 dark:text-dhagray-300;
}
.subgroup:not(.disabled) {
    @apply bg-dhagray-100 dark:bg-dhablue-500;
}
</style>

<script setup lang="ts" generic="OptionType extends Option">
import { computed } from 'vue';
import type { Option } from '@component-library/types';
import { createContiguousGroups } from './helpers';

const props = withDefaults(defineProps<{
    options: OptionType[];
    selected: OptionType['value'];
    label?: string | null;
    fontClass?: string;
}>(), {
    label: null,
    fontClass: 'font-supermolot-bold'
});

const emit = defineEmits<{
    (e: 'change', payload: OptionType['value']): void,
}>();

// Contiguous groups of options that are either all enabled or
// all disabled. Needed so they can have a shared background with
// rounded corners
const optionSubgroups = computed(() => createContiguousGroups(
    props.options,
    o => !!o.disabled
).map(({ value, items }) => ({
    disabled: value,
    options: items
})));

function onOptionClicked(option: OptionType) {
    emit('change', option.value);
}
</script>
