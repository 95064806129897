import { env } from '@api-client/envVars';

/**
 * Extract authorization code from the URL if it's there. If so, also
 * remove it from the URL without causing a redirect.
 *
 * @returns An authorization code if it exists
 */
export function getAuthorizationCodeFromUrl(): string | undefined {
    const search = new URLSearchParams(window.location.search);
    const code = search.get('code');
    const error = search.get('error');
    const errorDescription = search.get('error_description');

    const errorMessage = errorDescription ?? error as string;

    if (errorMessage) {
        const e = new Error(errorMessage);

        e.name = error ?? 'SAML Error';
        e.message = errorMessage;
        throw e;
    }

    if (!code) {
        return undefined;
    }

    const originalSearch = window.location.search;
    search.delete('code');
    const newSearchString = search.toString();
    window.history.replaceState(null, '', window.location.href.replace(
        originalSearch,
        newSearchString.length > 0 ? `?${newSearchString}` : ''
    ));

    return code;
}

/**
 * Exchange an authorization code for tokens and user info using the
 * server's oauth2 routes.
 *
 * @param code An authorization code
 * @returns Tokens and user info needed to initialize a client-side session
 */
export async function fetchTokensAndUserInfo(code: string): Promise<{
    idToken: string,
    accessToken: string,
    refreshToken: string,
    userInfo: {
        username: string
    }
}> {
    const tokensResponse = await fetch(`${env.API_HOST ?? ''}/oauth2/token?code=${code}`, {
        method: 'POST'
    });
    const tokens = await tokensResponse.json();
    const {
        id_token: idToken,
        access_token: accessToken,
        refresh_token: refreshToken
    } = tokens;

    const userInfoResponse = await fetch(`${env.API_HOST ?? ''}/oauth2/userInfo`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });
    const userInfo = await userInfoResponse.json();

    return {
        idToken,
        accessToken,
        refreshToken,
        userInfo
    };
}
