import { RootApiClient } from './RootApiClient';
import { env } from './envVars';
import { tracker } from './tracker';

export interface SupportChat {
    initAndIdentifyUser(): void;
    show(): void;
    hide(): void;
}

export class FrontSupportChat implements SupportChat {
    rootApiClient: RootApiClient;
    constructor(rootApiClient: RootApiClient) {
        this.rootApiClient = rootApiClient;
    }

    async initAndIdentifyUser(): Promise<void> {
        await this.rootApiClient.Auth.currentUser.then(u => u.freshAuth);
        const identifySupportUser = async () => {
            const userHash = await this.rootApiClient.getUserHash();
            // Important: Auth.currentUser is replaced when logging in. Always grab
            // a fresh reference before using it
            const user = await this.rootApiClient.Auth.currentUser;
            (globalThis).FrontChat('init', {
                chatId: env.FRONT_CHAT_ID,
                useDefaultLauncher: false,
                name: user.username,
                email: user.email,
                userHash
            });
        };
        // Important: Auth.currentUser is replaced when logging in. Always grab
        // a fresh reference before using it
        const user = await this.rootApiClient.Auth.currentUser;
        if (user && user.auth) {
            identifySupportUser();
        } else {
            this.rootApiClient.Auth.on('login', identifySupportUser);
        }
    }
    show() {
        tracker.track('Open Support');
        (globalThis).FrontChat('show');
    }
    hide() {
        (globalThis).FrontChat('hide');
    }
}

/* eslint-disable no-empty-function, @typescript-eslint/no-empty-function */
export class EmptySupportChat implements SupportChat {
    initAndIdentifyUser() {}
    show() {}
    hide() {}
}
/* eslint-enable no-empty-function, @typescript-eslint/no-empty-function */
