import type { MapLayer } from '@component-library/components/Map';

export class ToggleableMapLayer {
    readonly label: string;
    layers: MapLayer[];
    enabled: boolean;

    constructor(label: string, layers: MapLayer[], enabled = true) {
        this.label = label;
        this.layers = layers;
        this.enabled = enabled;
    }

    onLayerToggled(enabled: boolean) {
        this.enabled = enabled;
        this.layers = this.layers.map((layer) => ({
            ...layer,
            layout: {
                ...layer.layout,
                visibility: (enabled) ? 'visible' : 'none',
            }
        }));
    }
}
