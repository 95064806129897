import { reactive } from 'vue';
import { AuthComponentName, AuthUiState } from '../authenticationHelpers';
import { type LoginFlow } from './loginFlow';

export abstract class BaseUiState<Component extends AuthComponentName> implements AuthUiState<Component> {
    abstract component: Component;
    abstract formValues: AuthUiState<Component>['formValues'];
    abstract options: AuthUiState<Component>['options'];
    abstract handleEvent(...args: Parameters<AuthUiState<Component>['handleEvent']>): Promise<void>;

    private state = reactive<{
        loading?: boolean;
        error?: string;
        prompt?: string;
    }>({
        loading: undefined,
        error: undefined,
        prompt: undefined
    });

    loginFlow: LoginFlow;
    constructor(loginFlow: LoginFlow, args?: { prompt?: string }) {
        this.loginFlow = loginFlow;
        if (args?.prompt !== undefined) {
            this.prompt = args.prompt;
        }
    }

    get loading() {
        return this.state.loading;
    }
    set loading(value: boolean | undefined) {
        this.state.loading = value;
    }
    get error() {
        return this.state.error;
    }
    set error(value: string | undefined) {
        this.state.error = value;
    }
    get prompt() {
        return this.state.prompt;
    }
    set prompt(value: string | undefined) {
        this.state.prompt = value;
    }
}
