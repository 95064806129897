import * as t from 'io-ts';
import { date } from 'io-ts-types';

const metric = t.type({
    key: t.string,
    label: t.string,
    subLabel: t.string,
    formatter: t.union([t.string, t.undefined]),
    calc: t.union([t.string, t.undefined]),
    threshold: t.union([t.number, t.undefined]),
    lines: t.union([t.array(t.type({
        label: t.string,
        style: t.string,
        threshold: t.union([t.number, t.undefined])
    })), t.undefined]),
    interval: t.union([t.string, t.undefined]),
    bars: t.any,
    filter: t.any,
    yDomain: t.any
});

export const dashboardConfig = t.type({
    dashboard_tiles: t.array(t.type({
        title: t.string,
        subtitle: t.string,
        filters: t.any
    })),
    defaultZone: t.type({
        grouping: t.string,
        key: t.string,
        top: t.string
    }),
    description: t.string,
    drilldown: t.record(t.string, t.any),
    drilldownFields: t.array(t.string),
    filters: t.union([t.record(t.string, t.any), t.undefined]),
    groupings: t.record(t.string, t.any),
    labels: t.record(t.string, t.record(t.string, t.type({
        name: t.string,
        abbreviation: t.string
    }))),
    metrics: t.record(t.string, metric),
    mostRecentDate: date,
    name: t.string,
    orgTitle: t.string,
    userFilterDimension: t.string,
    zoomLevels: t.array(t.string)
});

export type DashboardConfig = t.TypeOf<typeof dashboardConfig>;
export type DashboardMetric = t.TypeOf<typeof metric>;
