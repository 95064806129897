/**
 * Resolves an absolute-or-relative logo image URL relative to the API
 * that returned it.
 *
 * @param logo A URL to a logo image that may be absolute or relative1
 * @param apiHost The API host that the logo URL came from
 * @returns An absolute URL to a logo image
 */
export function getAbsoluteLogoUrl(logo: string, apiHost: string) {
    const logoUrl = new URL(logo, apiHost);
    return logoUrl.toString();
}
