<template>
    <label
        class="flex gap-2 items-center"
        style="margin:0"
    >
        <input
            :checked="modelValue"
            class="cursor-pointer"
            style="margin:0"
            type="checkbox"
            @change="onChange"
        >
        <slot>
            <span
                v-if="label"
                class="font-supermolot font-normal text-base"
            >
                {{ label }}
            </span>
        </slot>
    </label>
</template>

<script setup lang="ts">
defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void,
}>();

function onChange(event: Event) {
    const el = event.target as HTMLInputElement;
    emit('update:modelValue', el.checked);
}
</script>
