import { getImage } from '@component-library/helpers';
import { MapboxButtonControl } from './MapboxButtonControl';

export class BearingButton extends MapboxButtonControl {
    constructor(onClick: () => void) {
        super(
            'mapbox-ctrl-bearing',
            'Bearing',
            getImage('bearing.svg'),
            () => onClick(),
        );
    }
}
