// Utility type that returns never if the input is undefined or null
type NotNil<T> = T extends undefined
    ? never
    : T extends null
        ? never
        : T;

/**
 * Check if a value is nil (null or undefined). Return false if so, true otherwise.
 *
 * The main use case is for narrowing array types. Calling .filter(isNotNil)
 * on an array will return an array with the null/undefined type removed.
 *
 * We wrap the return value in NotNil<T> to handle the case where T is instantiated
 * to undefined or null. In that case, we return false even though the return type
 * is "value is null", which should be true. By wrapping in NotNil<T>, we instead get
 * "value is never", which correctly returns false.
 *
 * @param value An arbitrary value
 * @returns false if the value is null or undefined, true otherwise
 */
export function isNotNil<T>(value: T | undefined | null): value is NotNil<T> {
    return value !== undefined && value !== null;
}

/**
 * Given a number n, returns the next number with a larger magnitude that has only
 * sigDigs significant digits
 * e.g.
 * * nextValueWithNSigDigs(12.5) -> 20
 * * nextValueWithNSigDigs(8) -> 10
 * * nextValueWithNSigDigs(3168) -> 4000
 * * nextValueWithNSigDigs(-26) -> -30
 * * nextValueWithNSigDigs(1234, 2) -> 1300
 *
 * @param n The data number that needs to be less than the returned value
 * @param sigDigs The number of significant digits
 */
export function nextValueWithNSigDigs(n: number, sigDigs = 1) {
    const sign = Math.sign(n);
    const magnitude = Math.abs(n);

    const log = Math.floor(Math.log10(magnitude) - (sigDigs - 1));
    const roundedUp = Math.ceil(magnitude / (10 ** log));

    return sign * roundedUp * (10 ** log);

    // Math.ceil(16 / (10 ** Math.floor(Math.log10(16)))) * (10 ** Math.floor(Math.log10(16)))
}
