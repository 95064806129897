export type LateResolvePromise<T = unknown> = Promise<T> & {
    resolve: (value: T | Promise<T>) => Promise<void>;
    reject: (err: any) => void;
};

/**
 * A promise with an external resolve function. Useful for allowing a client
 * to await a promise whose async process hasn't been started yet
 */
export function lateResolvePromise<T>(): LateResolvePromise<T> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let resolver: (value: T | Promise<T>) => Promise<void> = async () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let rejecter: (error: any) => Promise<void> = async () => {};
    const promise: LateResolvePromise <T> = (new Promise<T>((resolve, reject) => {
        resolver = async (value: T | Promise<T>) => {
            const resolvedValue = await value;
            resolve(resolvedValue);
        };
        rejecter = async (err: any) => {
            reject(err);
        };
    }) as LateResolvePromise<T>);

    promise.resolve = resolver;
    promise.reject = rejecter;
    return promise;
}
