import { DateTime } from 'luxon';

/**
 * Convert timestamp string to Fake UTC JS Date.
 *
 * Fake UTC Date: Local time in UTC is set to the local time in the tenant's timezone.
 *
 * @param strTimestamp Timestamp string in the format postgres returns when casting to ::TEXT
 * @param tsOptions Timezone options for the current tenant
 * @returns JS Date whose local time in the tenant's timezone is correct
*/
export function timestampToFakeUTCDate(strTimestamp: string, tsOptions: Intl.DateTimeFormatOptions) {
    return DateTime.fromSQL(strTimestamp)
        .setZone(tsOptions.timeZone)
        .toUTC(undefined, { keepLocalTime: true })
        .toJSDate();
}

/**
 * Convert a fake UTC Date to a timezoned Date.
 *
 * Fake UTC Date: Local time in UTC is set to the local time in the tenant's timezone.
 * Timezoned Date: Local time is set to the local time in the tenant's timezone.
 *
 * @param date JS Date whose local time in UTC is set to the local time in the tenant's timezone
 * @param tsOptions Timezone options for the current tenant
 * @returns JS Date whose local time in the tenant's timezone is correct
 */
export function fakeUTCDateToTimezonedDate(date: Date, tsOptions: Intl.DateTimeFormatOptions) {
    return DateTime.fromJSDate(date)
        .toUTC()
        .setZone(tsOptions.timeZone, { keepLocalTime: true })
        .toJSDate();
}
