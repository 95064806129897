<template>
    <form
        class="login-form flex flex-col w-full items-center"
        spellcheck="false"
        @submit.prevent=""
    >
        <fieldset
            class="flex flex-col w-full items-center border-none mb-8"
            :disabled="loading"
        >
            <!-- Name and id fields set for password managers -->
            <input
                id="username"
                ref="username"
                v-model="formValues.username"
                autocomplete="off"
                class="login-input"
                :class="{
                    disabled: disableUsername
                }"
                name="username"
                placeholder="email / username"
            >
            <input
                v-show="!hidePassword"
                id="password"
                ref="password"
                v-model="formValues.password"
                autocomplete="off"
                class="login-input"
                name="password"
                placeholder="password"
                type="password"
            >
            <div class="flex flex-row gap-2 self-start justify-center items-center">
                <input
                    v-model="formValues.remember"
                    class="w-4 h-4 cursor-pointer"
                    type="checkbox"
                >
                <div class="flex h-full text-dhagray-500 select-none">
                    remember me on this device
                </div>
            </div>
        </fieldset>
        <!-- Use row-reverse so continue is the first button because that's
        what gets called when the user presses enter -->
        <div class="flex flex-row-reverse gap-2">
            <button
                class="button"
                tabindex="-1"
                @click="emit('continue')"
            >
                {{ continueText }}
            </button>
            <button
                v-show="showBackButton"
                class="button secondary"
                tabindex="-1"
                @click="emit('back')"
            >
                Back
            </button>
        </div>
        <div
            class="text-button mt-2"
            @click="emit('forgot-password')"
        >
            Forgot Password?
        </div>
    </form>
</template>

<style lang="postcss" src="./common.pcss" scoped></style>

<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from 'vue';
import type { PropType } from 'vue';
import type { AuthUiState } from './authenticationHelpers';

const props = defineProps({
    formValues: {
        type: Object as PropType<AuthUiState<'login'>['formValues']>,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    continueText: {
        type: String,
        default: 'Login'
    },
    disableUsername: {
        type: Boolean,
        default: false
    },
    hidePassword: {
        type: Boolean,
        default: false
    },
    showBackButton: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits<{
    (e: 'back'): void;
    (e: 'continue'): void;
    (e: 'forgot-password'): void;
}>();

const username = ref<HTMLInputElement | null>(null);
const password = ref<HTMLInputElement | null>(null);

watch(
    () => [
        props.disableUsername,
        props.hidePassword
    ],
    () => focusNextField()
);

onMounted(() => focusNextField());

async function focusNextField() {
    await nextTick();
    if (!props.disableUsername) {
        (username.value as HTMLInputElement).focus();
    } else if (!props.hidePassword) {
        (password.value as HTMLInputElement).focus();
    }
}
</script>
