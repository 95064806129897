import {
    ToggleGroup,
    ToggleId,
    defaultFeatureToggleValues,
    featureToggleDefinitionsById,
    initFeatureToggles
} from 'common';
import { useSingletonComposable } from '@/composables/useSingletonComposable';

export const useFeatureToggles = useSingletonComposable(() => {
    const env = process.env.NODE_ENV === 'development' || process.env.TARGET_ENV === 'dev'
        ? 'dev'
        : 'prod';

    const toggles = initFeatureToggles<ToggleId, ToggleGroup>(
        featureToggleDefinitionsById,
        defaultFeatureToggleValues,
        [env]
    );

    const featureIsEnabled = (id: ToggleId) => toggles.featureIsEnabled(id);
    const getFeatureToggles = () => toggles.getFeatureToggles();
    const getFeatureTogglesInfo = () => toggles.getFeatureTogglesInfo();
    const saveUserFeatureToggleOverrides = (overrides: Record<ToggleId, boolean>) => toggles.saveUserFeatureToggleOverrides(overrides);
    const applyToggleGroup = (toggleGroup: ToggleGroup, enable = true) => toggles.applyToggleGroup(toggleGroup, enable);
    const getFeatureToggleSets = () => toggles.getToggleSets();

    return {
        featureIsEnabled,
        getFeatureToggles,
        getFeatureTogglesInfo,
        saveUserFeatureToggleOverrides,
        applyToggleGroup,
        getFeatureToggleSets
    };
});
