import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { User } from '@api-client';
import daAuthClient from '@api-client/auth';

export type { User };

export const useUserStore = defineStore('UserStore', () => {
    const currentUser = ref<User>();
    const userIsAdmin = computed(() => currentUser.value?.groups?.includes('org:darkhorse') || false);

    const loadUser = _.once(async () => {
        const user = await daAuthClient.currentUser;
        if (user && user.isAuthenticated) {
            currentUser.value = _.cloneDeep(user);
        } else {
            user.refresh().then(async () => {
                const u = await daAuthClient.currentUser;
                if (u.isAuthenticated) {
                    currentUser.value = _.cloneDeep(u);
                }
            });
            daAuthClient.on('login', async () => {
                currentUser.value = _.cloneDeep(await daAuthClient.currentUser);
            });
        }
    });

    watch(() => currentUser.value, (user) => {
        if (!user || !user.isAuthenticated) {
            Sentry.setUser(null);
        } else {
            Sentry.setUser({
                username: user.username
            });
        }
    }, { immediate: true, flush: 'sync' });

    /*
     * Logout the user and redirect to the login page
     */
    const logoutUser = async () => {
        // Logging out user has side-effect of redirecting to login page
        await daAuthClient.logout();
        currentUser.value = undefined;
    };

    return { currentUser, userIsAdmin, loadUser, logoutUser };
});
