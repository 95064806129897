import { ResultOf } from '@graphql-typed-document-node/core';
import type { FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import _ from 'lodash';
import { Duration } from 'luxon';
import {
    GetBaseMapBoundaryDocument,
    GetDiagnosticsDocument,
    GetDiagnosticsPropsDocument,
    GetInitialMapBoundsDocument,
    GetMetadataDocument,
    GetShapesDocument,
    GetTimeRangeCompletenessToleranceDocument,
    ListDiagnosticsDocument,
} from '@/generated/graphql-operations';
import type { TenantApiClient } from '../../TenantApiClient';
import { StandardizedNulls, standardizeNulls } from '../../helpers';
import type { LayerStyling } from '../../types';
import { perfDiagsChunkQuery } from './queries/perfDiagsChunk';

export type ListDiagnostics = StandardizedNulls<ResultOf<typeof ListDiagnosticsDocument>['api']['DiagnosticsAPI']['diagnosticsList']>;
type MetadataResult = StandardizedNulls<ResultOf<typeof GetMetadataDocument>['api']>;
export type Metadata = MetadataResult['DiagnosticsAPI']['metaData'] & {
    timezone: MetadataResult['timezone']
};

export type BaseMapBoundary = {
    geojson?: FeatureCollection<MultiPolygon | Polygon>;
    styleLayer?: LayerStyling;
};

export class DiagnosticsApi {
    api: TenantApiClient;
    constructor(api: TenantApiClient) {
        this.api = api;
    }
    async listDiagnostics(): Promise<ListDiagnostics> {
        const { DiagnosticsAPI } = standardizeNulls(await this.api.query(ListDiagnosticsDocument, { tenantId: this.api.db }).then(r => r.api));
        return DiagnosticsAPI.diagnosticsList;
    }

    async getDiagnosticsProps() {
        return standardizeNulls(await this.api.query(GetDiagnosticsPropsDocument, { tenantId: this.api.db }).then(r => r.api));
    }

    async getDiagnostics() {
        return standardizeNulls(await this.api.query(GetDiagnosticsDocument, { tenantId: this.api.db }).then(r => r.api));
    }

    async getPerfDiagsChunkGql(
        startTs: string,
        endTs: string,
        columnMetadata: {
            name: string;
            type: string;
        }[],
        columnsHash: string,
        diagnosticsId: string,
        chunkHash?: string
    ) {
        return this.api.query(perfDiagsChunkQuery, {
            tenantId: this.api.db,
            columnMetadata,
            startTs,
            endTs,
            columnsHash,
            chunkHash,
            diagnosticsId,
        }).then(r => r.api);
    }

    async getShapes() {
        const { DiagnosticsAPI } = standardizeNulls(await this.api.query(GetShapesDocument, { tenantId: this.api.db }).then(r => r.api));
        const shapes = DiagnosticsAPI.shapesGeojson.map((shape) => ({
            ...shape,
            geojson: JSON.parse(shape.geojson) as FeatureCollection
        }));
        const serviceArea = DiagnosticsAPI.serviceAreaGeojson
            ? JSON.parse(DiagnosticsAPI.serviceAreaGeojson) as FeatureCollection<MultiPolygon | Polygon>
            : undefined;
        return {
            shapes,
            serviceArea
        };
    }

    async getBaseMapBoundary(): Promise<BaseMapBoundary | undefined> {
        const { DiagnosticsAPI: { baseMapBoundary } } = standardizeNulls(
            await this.api.query(GetBaseMapBoundaryDocument, { tenantId: this.api.db }).then(r => r.api)
        );
        return baseMapBoundary as BaseMapBoundary | undefined;
    }

    async getTimeRangeCompletenessTolerance(): Promise<Duration> {
        const { DiagnosticsAPI: { timeRangeCompletenessTolerance } } = standardizeNulls(
            await this.api.query(GetTimeRangeCompletenessToleranceDocument, { tenantId: this.api.db }).then(r => r.api)
        );
        if (timeRangeCompletenessTolerance) {
            const { unit, count } = timeRangeCompletenessTolerance;
            return Duration.fromObject({ [unit]: count });
        }
        return Duration.fromObject({ hours: 6 });
    }

    async getInitialMapBounds(): Promise<Polygon | undefined> {
        const { initialMapBounds } = await this.api.query(
            GetInitialMapBoundsDocument,
            { tenantId: this.api.db, aggLevelConfigKey: 'diagnostics_app.initial_map_bounds_agg_level' }
        ).then(r => r.api);
        return standardizeNulls(initialMapBounds) as Polygon | undefined;
    }

    async getFullMetadata(diagnosticsId: string): Promise<Metadata> {
        const {
            timezone,
            DiagnosticsAPI,
        } = standardizeNulls(await this.api.query(GetMetadataDocument, {
            tenantId: this.api.db,
            diagnosticsId
        }).then(r => r.api));

        const {
            tabs,
            ...otherMetadata
        } = DiagnosticsAPI.metaData;

        return {
            timezone,
            tabs: _.map(tabs, (tab) => {
                if (!tab.choroplethColours && tab.mode === 'INTERVALS') {
                    return {
                        ...tab,
                        choroplethColours: {
                            colours: ['#FAF7D6', '#F7DDAC', '#F6C384', '#F3A868', '#EC8246', '#D7703C', '#CF6054', '#C14446', '#A42D43', '#8F244B', '#792051', '#5C1950'],
                            stops: [1 / 11, 2 / 11, 3 / 11, 4 / 11, 5 / 11, 6 / 11, 7 / 11, 8 / 11, 9 / 11, 10 / 11, 1]
                            // stops: [.1, .2, .3, .4, .5, .6, .7, .8, .9]
                        }
                    };
                }
                return tab;
            }),
            ...otherMetadata
        };
    }
}
