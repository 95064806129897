import { reactive } from 'vue';
import { passwordChallenge } from '@api-client/auth';
import { AuthUiState } from '../authenticationHelpers';
import { BaseUiState } from './baseAuthState';
import type { ConstructorArgs, LoginFlow } from './loginFlow';

export class RequestCodeUiState extends BaseUiState<'request-code'> {
    component = 'request-code' as const;
    formValues: AuthUiState<'request-code'>['formValues'] = reactive({
        username: '',
        password: '',
        remember: false,
        resetCode: '',
        verify: ''
    });
    readonly options: AuthUiState<'request-code'>['options'] = {};

    constructor(loginFlow: LoginFlow, args?: ConstructorArgs<'request-code'>) {
        super(loginFlow, args);
        if (args?.formValues) {
            if (args.formValues.username !== undefined) {
                this.formValues.username = args.formValues.username;
            }
            if (args.formValues.password !== undefined) {
                this.formValues.password = args.formValues.password;
            }
            if (args.formValues.remember !== undefined) {
                this.formValues.remember = args.formValues.remember;
            }
            if (args.formValues.resetCode !== undefined) {
                this.formValues.resetCode = args.formValues.resetCode;
            }
            if (args.formValues.verify !== undefined) {
                this.formValues.verify = args.formValues.verify;
            }
        }
    }

    async handleEvent(event: 'request-code' | 'back-to-login') {
        if (event === 'request-code') {
            await this.onRequestCode();
        } else if (event === 'back-to-login') {
            this.backToLogin();
        }
    }

    async onRequestCode() {
        this.prompt = `Sending Reset Request to: ${this.formValues.username}`;
        this.error = undefined;
        try {
            this.loading = true;
            const challenge = await passwordChallenge({ username: this.formValues.username.trim() }, true);
            this.loginFlow.transitionTo('reset-password', challenge, {
                formValues: this.formValues,
                options: { hasResetCode: true },
                prompt: challenge.prompt
            });
        } catch (err: any) {
            this.prompt = undefined;
            this.error = 'Request failed. Please try again.';
            throw new Error(err.message);
        } finally {
            this.loading = false;
        }
    }

    backToLogin() {
        this.loginFlow.transitionTo('login', {
            formValues: this.formValues
        });
    }
}
