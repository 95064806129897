<template>
    <div class="background flex fixed w-screen h-screen bg-white justify-center items-center">
        <loading-dialog
            class="bg-white"
            :tenant="(tenant ?? undefined)"
            title="Deployment"
        >
            <div class="font-roboto mb-2">
                <div class="flex w-full justify-center text-center">
                    {{ errorTitle }}
                </div>
                <div class="flex w-full justify-center text-center text-sm">
                    {{ details }}
                </div>
            </div>
            <div class="flex font-roboto-bold">
                <dha-button @click="onSupportClicked">
                    Contact Support
                </dha-button>
                <dha-button @click="onListClicked">
                    List
                </dha-button>
            </div>
        </loading-dialog>
    </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useSupportChat } from '@api-client';
import { LoadingDialog } from '@component-library';
import DhaButton from '@deployment-client/components/Button.vue';

defineProps({
    errorTitle: {
        type: String,
        required: true
    },
    details: {
        type: String,
        required: true
    },
    tenant: {
        type: String,
        default: null
    }
});

const support = useSupportChat();
const router = useRouter();

async function onListClicked() {
    await router.push({ name: 'list' });
    // Force a reload of the page
    window.location.reload();
}

function onSupportClicked() {
    support.show();
}
</script>
