import { FEATURE_TOGGLES_LOCAL_STORAGE_KEY } from 'common';
import { type Ref, onMounted, onUnmounted, ref } from 'vue';

export function useFeatureTogglesHaveUpdated(): { togglesHaveChanged: Ref<boolean> } {
    const togglesHaveChanged = ref(false);

    function update(event: StorageEvent) {
        if (event.key === FEATURE_TOGGLES_LOCAL_STORAGE_KEY) {
            togglesHaveChanged.value = true;
        }
    }

    onMounted(() => window.addEventListener('storage', update));
    onUnmounted(() => window.removeEventListener('storage', update));

    return {
        togglesHaveChanged,
    };
}
