import * as t from 'io-ts';

const dashboard = t.type({
    id: t.string,
    name: t.string,
    description: t.string
});

export const listDashboardsResult = t.type({
    DashboardsAPI: t.type({
        dashboardsList: t.array(dashboard)
    }),
    timezone: t.string
});
export type ListDashboardsResult = t.TypeOf<typeof listDashboardsResult>;
