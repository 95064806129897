// This will be populated by the injectEnvironment middleware when
// the frontend is served by the api-server, but undefined in development
const windowEnv: Record<string, string | undefined> = globalThis.env ?? {};

const env = {
    WEB_HISTORY_FALLBACK_ROUTE_DASHBOARD: process.env.WEB_HISTORY_FALLBACK_ROUTE_DASHBOARD,
    WEB_HISTORY_FALLBACK_ROUTE_DEPLOYMENT: process.env.WEB_HISTORY_FALLBACK_ROUTE_DEPLOYMENT,
    WEB_HISTORY_FALLBACK_ROUTE_DIAGNOSTICS: process.env.WEB_HISTORY_FALLBACK_ROUTE_DIAGNOSTICS,
    WEB_HISTORY_FALLBACK_ROUTE_HQ: process.env.WEB_HISTORY_FALLBACK_ROUTE_HQ,

    NODE_ENV: windowEnv.NODE_ENV ?? process.env.NODE_ENV,
    TARGET_ENV: windowEnv.TARGET_ENV ?? process.env.TARGET_ENV,
    VERSION: windowEnv.VERSION ?? '', // Empty string so we don't get errors if it's not set
    API_HOST: windowEnv.API_HOST ?? process.env.API_HOST,
    ALTERNATE_API_HOSTS: windowEnv.ALTERNATE_API_HOSTS ?? process.env.ALTERNATE_API_HOSTS,
    TOGGLES_STORAGE_KEY: windowEnv.TOGGLES_STORAGE_KEY ?? process.env.TOGGLES_STORAGE_KEY,

    MAPBOX_ACCESS_TOKEN: windowEnv.MAPBOX_ACCESS_TOKEN ?? process.env.MAPBOX_ACCESS_TOKEN,
    CUSTOMER_IO_API_KEY: windowEnv.CUSTOMER_IO_API_KEY ?? process.env.CUSTOMER_IO_API_KEY,
    FRONT_CHAT_ID: windowEnv.FRONT_CHAT_ID ?? process.env.FRONT_CHAT_ID,
    SENTRY_RELEASE: windowEnv.SENTRY_RELEASE ?? process.env.SENTRY_RELEASE,
    MIXPANEL_TOKEN: windowEnv.MIXPANEL_TOKEN ?? process.env.MIXPANEL_TOKEN,

    AWSREGION: windowEnv.AWSREGION ?? process.env.AWSREGION,
    COGNITOUSERPOOLID: windowEnv.COGNITOUSERPOOLID ?? process.env.COGNITOUSERPOOLID,
    COGNITOCLIENTID: windowEnv.COGNITOCLIENTID ?? process.env.COGNITOCLIENTID,
} as const;

export { env };
