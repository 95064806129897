import { Ref, shallowRef } from 'vue';
import { RootApiClient } from '../RootApiClient';

let apiClient: Ref<RootApiClient>;

export function useApiClient() {
    if (!apiClient) {
        apiClient = shallowRef(new RootApiClient());
    }

    return {
        apiClient
    };
}
