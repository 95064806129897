import { string } from 'fp-ts';
import { isLeft } from 'fp-ts/lib/These';
import { Type } from 'io-ts';

export function parse<T, A = T>(type: Type<T, A>, value: unknown): T {
    const result = type.decode(value);
    if (isLeft(result)) {
        const errors = result.left;
        console.log('errors', errors);
        throw new Error(`Parse error: \n${errors.slice(0, 10).map(error => `${type.name}: ${
            error.context.map(el => el.key).join('.')
        }: ${JSON.stringify(error.value)}`).join('\n')}`);
    }
    return result.right;
}

export function createParseFunction<T, A = T>(
    type: Type<T, A>,
    preprocess?: (rawValue: unknown) => unknown
) {
    return (value: unknown): T => {
        const processedValue = preprocess
            ? preprocess(value)
            : value;

        return parse(type, processedValue);
    };
}

export type DynamicQuery<
    Variables extends Record<string, any> = Record<string, any>,
    ResultType = any,
> = {
    builder: (v?: Variables) => {
        query: string,
        queryVariables: Record<string, any>
    },
    validator: (value: unknown) => ResultType
};

export function createDynamicQuery<
    Variables extends Record<string, any> = Record<string, any>,
    ResultType = any,
>(
    builder: (v?: Variables) => {
        query: string,
        queryVariables: Record<string, any>
    },
    validator: (value: unknown) => ResultType
) {
    return {
        builder,
        validator
    };
}
