import _ from 'lodash';
import { ref } from 'vue';

const initToast = _.once(() => {
    const toastState = ref<{
        visible: boolean,
        message: string
    }>({
        visible: false,
        message: ''
    });

    function showToast(message: string) {
        toastState.value = {
            visible: true,
            message
        };
    }

    return {
        toastState,
        showToast
    };
});

export function useToastState() {
    const { toastState } = initToast();
    return toastState;
}

export function showToast(message: string) {
    const { showToast } = initToast();
    showToast(message);
}
