<template>
    <div class="fixed w-screen h-screen flex">
        <router-view name="sidebar" />
        <div
            class="flex flex-grow overflow-y-auto overflow-x-hidden"
        >
            <router-view v-slot="{ Component }">
                <component
                    :is="Component"
                    v-if="Component"
                />
                <loading-overlay v-else />
            </router-view>
        </div>
        <dhe-toast />
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useFeatureTogglesHaveUpdated } from '@/composables/featureToggles/useFeatureTogglesHaveUpdated';
import { useUpdateFeatureTogglesForUser } from '@/composables/featureToggles/useUpdateFeatureTogglesForUser';
import { showToast } from '@/composables/useToast';
import { useUserStore } from '@/stores/UserStore';
import DheToast from '@/views/DheToast/DheToast.vue';
import {
    useMixpanel,
    useSupportChat
} from '@api-client';
import { onGraphqlVersionError } from '@api-client/graphql-client';
import { LoadingOverlay } from '@component-library';

const userStore = useUserStore();
userStore.loadUser();
useSupportChat();

const user = storeToRefs(userStore).currentUser;
useMixpanel(user);

const { togglesHaveChanged } = useFeatureTogglesHaveUpdated();
useUpdateFeatureTogglesForUser();

watch(togglesHaveChanged, t => {
    if (t) {
        showToast('Settings have been updated. Please refresh to see the latest changes.');
    }
}, { immediate: true, flush: 'sync' });
onGraphqlVersionError(_.once(() => {
    showToast('We\'ve just released a new version. Please refresh to see the latest changes.');
}));
</script>
