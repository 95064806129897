import type { Feature, MultiPolygon, Polygon } from 'geojson';
import { ref, toValue, watch } from 'vue';
import type { MaybeRefOrGetter, Ref } from 'vue';
import type { MapLayer, MapSource } from '@component-library';

export const SURROUNDING_AREA_DISPLAY_OPTIONS = {
    show: 0,
    fade: 0.5,
    hide: 1,
} as const;

export type SurroundingAreaVisibilityOption = keyof typeof SURROUNDING_AREA_DISPLAY_OPTIONS;

const SOURCE_ID = 'surroundingAreaSource';
const LAYER_ID = 'surroundingAreaLayer';

type ReturnType = {
    source: Ref<MapSource>;
    layer: Ref<MapLayer>;
};

export function useSurroundingAreaLayer(
    surroundingAreaFeature: MaybeRefOrGetter<Feature<MultiPolygon | Polygon> | undefined>,
    visibility: MaybeRefOrGetter<SurroundingAreaVisibilityOption>,
): ReturnType {
    const surroundingAreaFeatureValue = toValue(surroundingAreaFeature);
    const source = ref<MapSource>({
        id: SOURCE_ID,
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: (surroundingAreaFeatureValue)
                ? [surroundingAreaFeatureValue]
                : [],
        },
    });
    const layer = ref<MapLayer>({
        id: LAYER_ID,
        source: SOURCE_ID,
        type: 'fill',
        paint: {
            'fill-color': '#FFFFFF',
            'fill-opacity': SURROUNDING_AREA_DISPLAY_OPTIONS[toValue(visibility)],
        },
    });

    watch(() => toValue(surroundingAreaFeature), (newSurroundingAreaFeature) => {
        // Update the feature collection data for the boundary source
        source.value = {
            ...source.value,
            data: {
                ...source.value.data,
                features: (newSurroundingAreaFeature)
                    ? [newSurroundingAreaFeature]
                    : [],
            },
        };
    });

    watch(() => toValue(visibility), (newVisibility) => {
        // Update the layer opacity based on the selected visibility
        layer.value = {
            ...layer.value,
            paint: {
                ...layer.value.paint,
                'fill-opacity': SURROUNDING_AREA_DISPLAY_OPTIONS[newVisibility],
            },
        };
    });

    return {
        source,
        layer,
    };
}
