<template>
    <dhe-notification v-model="toastState.visible">
        <div class="flex flex-col font-supermolot font-normal text-base">
            <span>{{ toastState.message }}</span>
        </div>
    </dhe-notification>
</template>

<script setup lang="ts">
import { useToastState } from '@/composables/useToast';
import { DheNotification } from '@component-library';

const toastState = useToastState();
</script>
