import * as Sentry from '@sentry/vue';
import { App, Plugin } from 'vue';
import { env } from '@api-client/envVars';

export const SentryPlugin: Plugin = {
    install(app: App, options: Parameters<typeof Sentry['init']>[0]) {
        if (env.SENTRY_RELEASE) {
            const environment = env.TARGET_ENV ?? 'localhost';
            console.info(`Init sentry with release: ${env.SENTRY_RELEASE} and environment: ${environment}`);
            Sentry.init({
                app,
                release: env.SENTRY_RELEASE,
                environment,
                ...options,
                // This is safe to include directly in source
                dsn: 'https://1643f408e5ef4ad090fd5511c3aa641d@o1325976.ingest.sentry.io/6585570',
            });
        }
    }
};
