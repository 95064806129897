import type { App, AppName } from '@api-client/types';
import { env } from './envVars';

const APP_ORIGINS = {
    Home: '/hq',
    Deployment: '/deployment',
    Diagnostics: '/diagnostics',
    Dashboards: '/dashboard'
};

export type EnvConfig = {
    apiHost: string;
    appHost: string;
    isDeployed: boolean;
    apiIsDeployed: boolean;
    appConfigs: Record<AppName, App>;
}

export function getEnvConfig(options?: {
    apiHost?: string;
    appHost?: string;
}): EnvConfig {
    // If we're explicitly configuring the API_HOST, use that
    // Otherwise, use the same URL this app is hosted on
    // We use || and not ?? because we want to use location.origin
    // if API_HOST is ''
    const apiHost = options?.apiHost ?? (env.API_HOST || window.location.origin);
    const appHost = options?.appHost ?? window.location.origin;

    return {
        apiHost,
        appHost,
        isDeployed: appHost.includes('darkhorse.app'),
        apiIsDeployed: apiHost.includes('darkhorse.app'),
        appConfigs: {
            Home: {
                name: 'Home',
                url: APP_ORIGINS.Home,
                icon: 'house',
            },
            Deployment: {
                name: 'Deployment',
                url: APP_ORIGINS.Deployment,
                icon: 'add_location_alt',
                image: 'deployment',
            },
            Diagnostics: {
                name: 'Diagnostics',
                url: APP_ORIGINS.Diagnostics,
                icon: 'analytics',
                image: 'diagnostics',
            },
            Dashboards: {
                name: 'Dashboards',
                url: APP_ORIGINS.Dashboards,
                icon: 'space_dashboard',
                image: 'dashboard',
            }
        }
    };
}
export const envConfig = getEnvConfig();
