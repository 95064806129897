<template>
    <teleport to="body">
        <div class="notification-container z-50 flex fixed top-0 w-full justify-center pointer-events-none">
            <div
                ref="notificationContent"
                class="flex absolute max-w-sm gap-3 justify-between items-center py-1 px-2 bg-dhagray-50
                border border-highlight-blue shadow-card rounded-md pointer-events-auto"
                style="top:"
                :style="{
                    top: `-${height + PADDING}px`,
                    transform: `translateY(${modelValue ? (height + (2 * PADDING)) : 0}px)`,
                    transition: 'transform 500ms ease'
                }"
            >
                <slot>
                    <span
                        v-if="message"
                        class="font-supermolot font-normal text-base"
                    >
                        {{ message }}
                    </span>
                </slot>
                <button
                    class="flex justify-center items-center bg-dhagray-50 hover:bg-dhagray-100
                    rounded-md p-1"
                    style="transition: background-color 200ms ease"
                >
                    <span
                        class="material-icons text-dhagray-500"
                        style="font-size:24px;"
                        title="Dismiss"
                        @click="emit('update:modelValue', false)"
                    >
                        close
                    </span>
                </button>
                <!-- TODO: Ask design about this -->
                <!-- <div
                    class="ping-icon flex absolute w-3 h-3"
                    style="top:-4px;right:-4px"
                >
                    <div
                        class="absolute w-full h-full rounded-full bg-highlight-blue
                        opacity-75 animate-ping"
                    />
                    <div
                        class="absolute w-full h-full rounded-full bg-highlight-blue"
                    />
                </div> -->
            </div>
        </div>
    </teleport>
</template>

<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { ref } from 'vue';

defineProps({
    modelValue: {
        type: Boolean,
        required: true,
    },
    message: {
        type: String,
        default: undefined,
    }
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void,
}>();

const PADDING = 10;

const notificationContent = ref<HTMLDivElement>();
const { height } = useElementBounding(notificationContent);
</script>
