import _ from 'lodash';

/**
 * Applies fn to transform the value of obj[path]. Concise way to mutate
 * a deeply nested property of the object
 *
 * NOTE: Mutates in-place rather than returning a new object
 *
 * @param obj An object to modify
 * @param path The path to modify
 * @param fn Function to apply to path in obj
 */
export function applyAtPath(obj: any, path: string, fn: (arg: any) => any): void {
    _.set(obj, path, fn(_.get(obj, path)));
}

/**
 * Camel-cases all of the keys of an object
 *
 * {some_prop: 1} => {someProp: 1}
 * @param obj The object to transform
 * @returns The same object, with all of its keys camel-cased
 */
export function camelCaseKeys(obj: any) {
    return _.mapKeys(obj, (value, key) => _.camelCase(key));
}

export type StandardizedNulls<T> = T extends null ? undefined
    : {[P in keyof T]: StandardizedNulls<T[P]>};

/**
 * Deeply transforms an object, converting all nulls to undefined
 *
 * @param value An object containing a mixture of nulls and undefineds
 */
export function standardizeNulls<T>(value: T): StandardizedNulls<T> {
    if (value instanceof Array) {
        return value.map(el => standardizeNulls(el)) as any;
    }

    if (value instanceof Object) {
        return _.mapValues(value as any, prop => standardizeNulls(prop)) as any;
    }

    if (value === undefined || value === null) {
        return undefined as any;
    }
    return value as any;
}
