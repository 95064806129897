import type { HqConfig } from '../client';

type ValidatedConfig = {
    config: HqConfig,
    errors?: string;
};

/**
 * Check the provided HqConfig object for errors. Collect all errors into a user-friendly error
 * message and remove all erroneous entries from the config.
 */
export function validateConfig(config: HqConfig): ValidatedConfig {
    const configuredMetricIds = config.metrics.map((metric) => metric.id);
    const configuredMetricCards = config.metricCards.map((card) => card.id);
    const metricIdErrors: string[] = [];
    const metricCardErrors: string[] = [];

    const metricCards = config.metricCards.filter((card) => {
        if (!configuredMetricIds.includes(card.metricId)) {
            metricIdErrors.push(card.metricId);
            return false;
        }
        return true;
    });

    const filteredMetricCardIds = metricCards.map((card) => card.id);
    const sections = config.sections.map((section) => ({
        ...section,
        metricCards: section.metricCards
            // Remove refs to metric cards that do not have an entry in config.metricCards
            .filter((cardId) => {
                if (!configuredMetricCards.includes(cardId)) {
                    metricCardErrors.push(cardId);
                    return false;
                }
                return true;
            })
            // Remove refs to metric cards that have been filtered out b/c of an invalid metric ID
            .filter((cardId) => filteredMetricCardIds.includes(cardId))
    }));

    const reports = config.reports.filter((report) => {
        if (!configuredMetricIds.includes(report.metricId)) {
            metricIdErrors.push(report.metricId);
            return false;
        }
        return true;
    });

    // Combine all config section errors
    const allErrors: string[] = [];
    if (metricIdErrors.length > 0) {
        allErrors.push(`metric IDs that don't exist: [${metricIdErrors.join(', ')}]`);
    }
    if (metricCardErrors.length > 0) {
        allErrors.push(`metric cards that don't exist: [${metricCardErrors.join(', ')}]`);
    }

    return {
        config: {
            ...config,
            metricCards,
            sections,
            reports,
        },
        errors: (allErrors.length > 0)
            ? `Error: Config references ${allErrors.join(' and ')} `
                .concat('(Cards and Reports referencing these entries have been removed)')
            : undefined
    };
}
