import { watchOnce } from '@vueuse/core';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { LoginFlow } from './loginFlow';
import { SsoPasswordStepUiState } from './ssoPasswordStep';

export function useLoginFlow() {
    const loginFlow = new LoginFlow();

    const route = useRoute();
    const routeError = route.query.error_description ?? route.query.error;
    if (routeError && typeof routeError === 'string') {
        loginFlow.currentState.value.error = routeError;
    }

    const currentUiState = computed(() => loginFlow.currentState.value);

    watch([
        () => loginFlow.currentState.value.formValues.username,
        () => loginFlow.currentState.value.formValues.password,
        () => loginFlow.currentState.value.formValues.resetCode,
        () => loginFlow.currentState.value.formValues.verify
    ], () => {
        loginFlow.currentState.value.error = undefined;
    });

    watch([
        () => loginFlow.currentState.value.formValues.username
    ], (u) => {
        if (loginFlow.currentState.value instanceof SsoPasswordStepUiState) {
            loginFlow.currentState.value.handleEvent('back');
        }
    });

    watchOnce(
        () => loginFlow.currentState.value.error,
        () => {
            const url = new URL(window.location.href);
            url.searchParams.delete('error');
            url.searchParams.delete('error_description');

            window.history.replaceState({}, '', url);
        }
    );

    return {
        currentUiState
    };
}
