import { RouteRecordRaw } from 'vue-router';

const DeploymentAuditorComponent = () => import('@/pages/DeploymentAuditor');

export const route: RouteRecordRaw = {
    name: 'DeploymentAuditor',
    path: '/deploymentAuditor/:tenant',
    component: DeploymentAuditorComponent,
    meta: { title: 'Deployment Auditor' }
};
