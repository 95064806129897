import * as t from 'io-ts';

export const perfDiagsChunkSchema = t.type({
    api: t.type({
        DiagnosticsAPI: t.type({
            perfDiagsChunk: t.type({
                perfDiags: t.array(t.record(t.string, t.union([
                    t.string,
                    t.number,
                    t.boolean,
                    t.null
                ]))),
                columnsChanged: t.boolean,
                chunkHash: t.union([t.string, t.undefined])
            })
        })
    })
});

export type PerfDiagsChunk = t.TypeOf<typeof perfDiagsChunkSchema>;
