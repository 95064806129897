import _ from 'lodash';
import { RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router';

// Helper that keeps track of the last route before entering a particular
// page, so we can revert to it after closing said page. Specifically, created
// for a deep, complex settings page. This lets us remember which report/page
// was open before navigating through many settings routes
export class NavigateToBeforeHelper {
    private lastRouteBefore?: RouteLocationNormalized;
    private routeName: string;
    private getDefaultRoute: (r: Router) => RouteLocationRaw;

    constructor(routeName: string, options?: {
        getDefaultRoute: (r: Router) => RouteLocationRaw
    }) {
        this.routeName = routeName;
        this.getDefaultRoute = options?.getDefaultRoute ?? (() => '/');
    }

    onBeforeEnter(from: RouteLocationNormalized) {
        // Don't track the previous route if:
        //  * We navigated directly to /settings from an external site (0 routes matched)
        //  * We're navigating from another settings page
        if (from.matched.length > 0 && !_.find(from.matched, { name: this.routeName })) {
            this.lastRouteBefore = from;
        }
    }

    navigateToLastLink(router: Router, refresh = true) {
        if (refresh) {
            console.log('navigateToLastLink');
            if (this.lastRouteBefore) {
                const route = router.resolve(this.lastRouteBefore);
                window.location.href = route.href;
            } else {
                const route = router.resolve(this.getDefaultRoute(router));
                window.location.href = route.href;
            }
        } else if (this.lastRouteBefore) {
            router.push(this.lastRouteBefore);
        } else {
            router.push(this.getDefaultRoute(router));
        }
    }
}

export const beforeSettingsHelper = new NavigateToBeforeHelper(
    'TenantSettings',
    {
        getDefaultRoute: r => ({
            name: 'Home',
            params: {
                tenant: r.currentRoute.value.params.tenant
            }
        })
    }
);
