import { reactive } from 'vue';
import { env } from '@api-client/envVars';
import { AuthUiState } from '../authenticationHelpers';
import { BaseUiState } from './baseAuthState';
import type { ConstructorArgs, LoginFlow } from './loginFlow';

export class SsoUserStepUiState extends BaseUiState<'login'> {
    component = 'login' as const;
    formValues: AuthUiState<'login'>['formValues'] = reactive({
        username: '',
        password: '',
        remember: false,
        resetCode: '',
        verify: ''
    });

    readonly options: AuthUiState<'login'>['options'] = {
        continueText: 'continue',
        disableUsername: false,
        hidePassword: true,
        showBackButton: false
    };

    constructor(loginFlow: LoginFlow, args?: ConstructorArgs<'login'>) {
        super(loginFlow, args);
        if (args?.formValues) {
            if (args.formValues.username !== undefined) {
                this.formValues.username = args.formValues.username;
            }
            if (args.formValues.password !== undefined) {
                this.formValues.password = args.formValues.password;
            }
            if (args.formValues.remember !== undefined) {
                this.formValues.remember = args.formValues.remember;
            }
            if (args.formValues.resetCode !== undefined) {
                this.formValues.resetCode = args.formValues.resetCode;
            }
            if (args.formValues.verify !== undefined) {
                this.formValues.verify = args.formValues.verify;
            }
        }
    }

    async handleEvent(event: 'continue' | 'back' | 'forgot-password') {
        if (event === 'continue') {
            if (this.formValues.username.length === 0) {
                this.error = 'Please enter your username';
                return;
            }

            this.loading = true;
            await this.checkUsername();
            this.loginFlow.transitionTo('password', {
                formValues: this.formValues
            });
        } else if (event === 'forgot-password') {
            await this.forgotPassword();
        } else {
            throw new Error(`Unsupported event: ${event}`);
        }
    }

    private async checkUsername() {
        const checkEmailRoute = env.API_HOST
            ? `${env.API_HOST}/cognito-idp/checkEmail`
            : '/cognito-idp/checkEmail';

        const checkEmail = await fetch(checkEmailRoute, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                email: this.formValues.username
            })
        });
        const content = await checkEmail.json();
        if (content.redirectTo) {
            // Never resolve so we don't get a login error by accidentally trying
            // to log in too early
            return new Promise<void>(resolve => {
                window.location.href = content.redirectTo;
            });
        }
        return Promise.resolve();
    }

    async forgotPassword() {
        this.loginFlow.transitionTo('request-code', {
            formValues: this.formValues,
            prompt: 'Please enter your username'
        });
    }
}
