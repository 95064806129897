<script setup lang="ts">
import { until } from '@vueuse/core';
import type { LngLatBoundsLike } from 'mapbox-gl';
import { computed, ref } from 'vue';
import { env } from '@api-client/envVars';
import type {
    MapLayer,
    MapSource,
} from '@component-library/components/Map';
import DhaMap from '@component-library/components/Map/Map.vue';
import { decodeImageUri } from '@component-library/helpers';

const props = withDefaults(defineProps<{
    sources: MapSource[];
    layers: MapLayer[];
    bearing?: number
}>(), {
    bearing: 0,
});

const mapInstance = ref<InstanceType<typeof DhaMap> | null>(null);
const accessToken = computed(() => env.MAPBOX_ACCESS_TOKEN || 'missing Mapbox access token');

async function generateImageUri(): Promise<string> {
    await until(mapInstance).toBeTruthy();
    return mapInstance.value!.generateImageUri();
}

async function generateImage(): Promise<HTMLImageElement> {
    const imageUri = await generateImageUri();
    return decodeImageUri(imageUri);
}

function jumpToBounds(bounds: LngLatBoundsLike, options?: { padding?: number }) {
    mapInstance.value?.jumpToBounds(bounds, options);
}

defineExpose({
    generateImageUri,
    generateImage,
    jumpToBounds,
});
</script>

<template>
    <DhaMap
        ref="mapInstance"
        :access-token="accessToken"
        class="invisible"
        :initial-bearing="bearing"
        :interactive="false"
        :layers="layers"
        :sources="sources"
        style-url="mapbox://styles/developmentdha/cktu5qui808zh18r51frofrxp"
    />
</template>
