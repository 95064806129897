import { type MaybeElementRef, useMutationObserver, useResizeObserver } from '@vueuse/core';
import { select } from 'd3';
import { type Ref, nextTick, onMounted, reactive, ref, toRefs, watch } from 'vue';

/**
 * Get an image from the component-library by name
 *
 * This is used by apps to fetch images by dynamically
 * requiring them, so they don't have to know the exact asset
 * path in component-library
 *
 * @param name The name of the image to get
 * @returns The imported, resolved, image
 */
export function getImage(name: string): any {
    return new URL(`/component-library/src/assets/images/${name}`, import.meta.url).href;
}

/**
 * Decode an image URI into an HTMLImageElement
 */
export async function decodeImageUri(uri: string): Promise<HTMLImageElement> {
    const image = new Image();
    image.src = uri;
    await image.decode();
    return image;
}

export type Bbox = {
    x: number;
    y: number;
    width: number;
    height: number;
}

/**
 * Return an object for right padding that addresses a Firefox bug that
 * doesn't account for `letter-spacing` when determining how wide a <select>
 * should be. (Example: { paddingRight: 2px })
 *
 * @param fontSizePx         The font size used for the <select>, in pixels
 * @param baseRightPaddingPx The right padding required in addition to the
 *                           amount added as a workaround (this is the padding
 *                           between the text and the chevron)
 * @param letterSpacing      The CSS letter-spacing used for the <select>, expressed as
 *                           a decimal (e.g. 0.05, 0.1, etc)
 * @param optionStrings      The strings that will be displayed as options in the
 *                           <select>
 *
 * @returns The appropriate style object. Empty if browser is not Firefox.
 */
export function getSelectWorkaroundRightPaddingStyle(
    fontSizePx: number,
    baseRightPaddingPx: number,
    letterSpacing: number,
    optionStrings: string[],
) {
    const firefoxUserAgentPattern = 'Gecko/[0-9]+';
    if (!navigator.userAgent.match(firefoxUserAgentPattern)) {
        return { };
    }

    // Tailwind-applied letter spacing (`tracking-wider`):

    const longestLabel = optionStrings.reduce(
        (currentMaxLen, optionString) => (
            optionString.length > currentMaxLen ? optionString.length : currentMaxLen
        ),
        0
    );

    // We're going to compensate for the spacing between letters that gets added
    // by letter-spacing (tailwindcss tracking-*)
    const numSpacesBetweenLetters = longestLabel - 1;

    // Determine the amount of spacing added by letter-spacing
    const addedSpace = numSpacesBetweenLetters * letterSpacing * fontSizePx;

    return { paddingRight: `${baseRightPaddingPx + addedSpace}px` };
}

/**
 * Watch an SVG element for resize events, return a reactive set
 * of bounding box values
 *
 * Note that SVG elements need to be watched for changes by both
 * a resizeObserver and a mutationObserver to cover all potential changes.
 *
 * @param target A Vue ref to either the element being watched, or null
 */
export function useSvgElementBbox(
    elementRef: MaybeElementRef
) {
    const bbox = reactive({
        x: 0,
        y: 0,
        width: 0,
        height: 0
    });
    useSvgResizeObserver(elementRef, async () => {
        await nextTick();
        if (!elementRef) {
            return;
        }
        const { x, y, width, height } = (elementRef as Ref<SVGSVGElement>).value.getBBox();

        if (x !== bbox.x) { bbox.x = x; }
        if (y !== bbox.y) { bbox.y = y; }
        if (width !== bbox.width) { bbox.width = width; }
        if (height !== bbox.height) { bbox.height = height; }
    });

    return toRefs(bbox);
}

/**
 * Watch an SVG element for resize events and call a callback
 *
 * Note that SVG elements need to be watched for changes by both
 * a resizeObserver and a mutationObserver to cover all potential changes.
 *
 * @param target A Vue ref to either the element being watched, or null
 * @param callback a callback to call when the ref changes size
 */
export function useSvgResizeObserver(
    elementRef: MaybeElementRef,
    cb: () => void
) {
    useMutationObserver(elementRef, cb, {
        childList: true,
        characterData: true
    });
    useResizeObserver(elementRef, cb);

    onMounted(cb);
}

/**
 * Watch a ref and record an array of all changes
 *
 * Primarily for debugging any flaky bbox-watching/dynamic resizing
 * code
 *
 * @param valueRef A ref to a numeric value
 * @returns A ref to an array of all values of ref
 */
export function watchValueHistory(
    valueRef: Ref<number>
): Ref<string[]> {
    const history = ref<string[]>([]);
    watch(
        () => valueRef.value,
        value => history.value.push(value.toFixed(1))
    );

    return history;
}

export function isSafari(includeNonSafariOniOS = true) {
    // All iOS/iPadOS browsers are forced to use Webkit rather than their own rendering engine
    // Therefore they typically have similar issues to Safari
    const isSafariOriOS = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Note that the iOS browsers don't include Chrome/Firefox/Edge etc in their useragent string,
    // they include CriOS/FxiOS/EdgiOS instead.
    const isSafari = /^((?!chrome|android|fxios|crios|edgios).)*safari/i.test(navigator.userAgent);

    return includeNonSafariOniOS ? isSafariOriOS : isSafari;
}

export const measureSvgText = (
    text: string,
    fontSize: number,
    fontFamily: string,
    fontWeight = 400
): { width: number; height: number } => {
    if (!text || text.length === 0) {
        return { width: 0, height: 0 };
    }

    const svg = select('body').append('svg');
    svg
        .append('text')
        .attr('font-family', fontFamily)
        .attr('font-size', fontSize)
        .attr('font-weight', fontWeight)
        .attr('x', -1000)
        .attr('y', -1000)
        .text(text);

    const bounds = svg.node()?.getBBox();
    svg.remove();

    return { width: bounds?.width || 0, height: bounds?.height || 0 };
};
