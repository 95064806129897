/*
Wrapper for all usage of amazon-cognito-identity-js
for use with Cognito User Pool

 Code is from examples here
 https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js

 Found the SDK docs:
 https://gerardnico.com/aws/cognito/js_identity

 There is now also an npm page:
 https://www.npmjs.com/package/amazon-cognito-identity-js
*/

/* expired access token for testing
eyJraWQiOiJKelJqOGlNcEFnTFg2S0ExNkw1T25TdXdib0NKS0wzZTNTajZFQm9Vb0hVPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmZjM0ZmI0Yy1iNTIzLTRhMDUtYWY3Yy1hNGNiOTViNGZlMjEiLCJjb2duaXRvOmdyb3VwcyI6WyJvcmc6Y3QiLCJvcmc6YWZycyIsIm9yZzpwZyIsIm9yZzplcHMiLCJvcmc6ZGFya2hvcnNlIiwib3JnOmVvcCIsIm9yZzp2ZnJzIiwib3JnOmhwcyIsIm9yZzpnb3RoYW0iLCJvcmc6c2NlcyIsIm9yZzplbXMiLCJvcmc6aG9uZXN0ZG9vciIsIm9yZzpyb2Jib3giLCJvcmc6a2ZkX2Rpc3BhdGNoOmFsbCIsImVtcyIsIm9yZzpsZWR1YyIsIm9yZzpkZW1vIiwib3JnOnNhbmRib3giLCJvcmc6ZGF0YW1hcnNoIiwib3JnOmtmZCIsIm9yZzp1ZnNhIiwib3JnOmJmZCIsIm9yZzp0ZnMiLCJvcmc6a2ZkX2Rpc3BhdGNoIiwib3JnOnRlc3Rmb2xkZXIiLCJvcmc6aGZkIl0sImV2ZW50X2lkIjoiZTMzOWQ0NDktNWM5MS00NTYyLWJiZGUtZjRmMzdmYjE0NWZmIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTYwMDI4NTUwMSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLXdlc3QtMi5hbWF6b25hd3MuY29tXC91cy13ZXN0LTJfbEVmVEdKMzNKIiwiZXhwIjoxNjAwNDUxMDc4LCJpYXQiOjE2MDA0NDc0NzksImp0aSI6IjNlMDNhNDZiLWMwMTUtNDEyYy1hYjEzLWY5OWJjMDMwMjc2ZSIsImNsaWVudF9pZCI6IjI2cmZjcmFwYTM4MmUxMThmcTNrbGllYWQ2IiwidXNlcm5hbWUiOiJhbGFuIn0.eyvSbcrJAJ9rJMZnboQfc46YJeSRCDCgeg1pDLwwP25g8J8kuzGMJta7E1NeGeRp2-BriDJX75zTR2wi-nMWZIymRWby9LoYbxfpEDhHhrZQiJrnYiiE0YsPS-xX7FxE4R7b3h9B8SVMdRaPVm_DgHrtq2_otcagfeCftbsbthpDwV4qWe-xtEsAomb0kc08V8UXQC4OpeSjfAJpek2UpZqeIu7w_Mk6ccvoVkCvzLxsO5sNtVMmJo63sOwE9LEZeaq_fAWD3-siM0bMt_Rr1EQedInUELPHKt9hFoPA0Z_10Z67yVPYBUX3B7yDJ54nAGwoasKQkyuh-2rLVS-EEg
*/

import {
    AuthenticationDetails,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
    CognitoUserSession,
    IAuthenticationDetailsData
} from 'amazon-cognito-identity-js';
import { env } from '@api-client/envVars';
import {
    getStorage
} from './storage';

/* eslint-disable no-param-reassign, prefer-promise-reject-errors */
const {
    AWSREGION,
    COGNITOUSERPOOLID,
    COGNITOCLIENTID,
} = env;
const awsEnv = {
    AWSREGION,
    COGNITOUSERPOOLID,
    COGNITOCLIENTID,
};

if (!awsEnv.COGNITOCLIENTID || !awsEnv.COGNITOUSERPOOLID) {
    console.warn('COGNITOUSERPOOLID/COGNITOCLIENTID not set. Auth will not work');
    throw new Error('Missing Cognito User Pool settings');
}
awsEnv.AWSREGION = awsEnv.AWSREGION || awsEnv.COGNITOUSERPOOLID.split('_')[0];

const Storage = getStorage({ allowCookieStorage: false });

const endpoint = `${env.API_HOST ?? ''}/cognito-idp`;

console.log('Connect to cognito endpoint', endpoint);
const userPool = new CognitoUserPool({
    UserPoolId: awsEnv.COGNITOUSERPOOLID,
    ClientId: awsEnv.COGNITOCLIENTID,
    Storage,
    endpoint
});

function getUser(username: string) {
    const userData = {
        Username: username,
        Pool: userPool,
        Storage
    };
    return new CognitoUser(userData);
}

function getSession(cognitoUser: CognitoUser) {
    return new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        }, {
            // Workaround: for refresh token flows, we need the username
            // so we can compute SECRET_HASH, but that's not part of the
            // 'REFRESH_TOKEN' payload - so we put the username here as a
            // fallback
            clientMetadata: {
                username: cognitoUser.getUsername()
            }
        });
    });
}

export function getUserFromTokens(
    username: string,
    IdToken: string,
    AccessToken: string,
    RefreshToken: string
): CognitoUser {
    const session = new CognitoUserSession({
        AccessToken: new CognitoAccessToken({ AccessToken }),
        IdToken: new CognitoIdToken({ IdToken }),
        RefreshToken: new CognitoRefreshToken({ RefreshToken })
    });
    const user = new CognitoUser({
        Username: username,
        Pool: userPool,
        Storage
    });
    user.setSignInUserSession(session);
    return user;
}

function forgotPassword(cognitoUser: CognitoUser): Promise<any> {
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function getUserAttributes(cognitoUser: CognitoUser) {
    return new Promise<CognitoUserAttribute[]>((resolve, reject) => {
        cognitoUser.getUserAttributes((err, attributeList) => {
            if (err) {
                reject(err);
            } else {
                resolve(attributeList!);
            }
        });
    });
}

function completeNewPasswordChallenge(cognitoUser: CognitoUser, { password, challengeAttributes }: { password: string; challengeAttributes: any; }) {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(password, challengeAttributes, {
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function confirmPassword(cognitoUser: CognitoUser, { resetCode, password }: { resetCode: string; password: string; }) {
    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(resetCode, password, {
            onSuccess: resolve,
            onFailure: reject
        });
    });
}

function authenticateUser(cognitoUser: CognitoUser, { password }: { password: string; remember?: boolean }) {
    const authenticationData: IAuthenticationDetailsData = {
        Username: cognitoUser.getUsername(),
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    // TODO: need to set flow based on the flow configured in user pool
    // console.log('Setting to user password auth');
    // cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    return new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: resolve,
            // username/Password invalid, any other errors
            onFailure: reject,
            // New Password required
            newPasswordRequired: (userAttributes, e) => {
                delete userAttributes.email_verified;
                delete userAttributes.email;
                reject({
                    message: 'Temporary Password Change Required',
                    challenge: { newPasswordRequired: userAttributes }
                });
            }
        });
    });
}

function getCurrentUser() {
    return userPool.getCurrentUser();
}

export { forgotPassword, confirmPassword, getSession, getUser, authenticateUser, getCurrentUser, getUserAttributes, completeNewPasswordChallenge, awsEnv };
