import _ from 'lodash';
import { Component } from 'vue';
import type { NavigationGuardNext, RouteLocation, RouteLocationNormalized, RouteRecordRaw, Router } from 'vue-router';
import type { AuthClient } from './auth-client';

/* eslint-disable no-param-reassign, consistent-return */

type AuthRouterConstructorArgs = {
    authClient: AuthClient;
    router: Router;
    defaultPath?: string;
}

function withTrailingSlash(s: string) {
    if (s.endsWith('/')) {
        return s;
    }
    return `${s}/`;
}

export class AppAuthRouterVue {
    rootPath: string;
    authClient: AuthClient;
    loginRoute: RouteRecordRaw;
    router: Router;

    constructor(rootPath: string, authClient: AuthClient, loginComponent: Component, router: Router) {
        this.rootPath = rootPath;
        this.authClient = authClient;
        this.loginRoute = {
            path: `${withTrailingSlash(rootPath)}login`,
            name: `${withTrailingSlash(rootPath)}Login`,
            component: loginComponent
        };
        this.router = router;
    }

    routeMatches(r: RouteLocationNormalized) {
        return r.path.startsWith(this.rootPath);
    }

    // Checks if a given route has the noAuth meta tag
    noAuth(route: RouteLocationNormalized) {
        return !route.matched.some(record => record.meta.noAuth !== true && record.path !== this.loginRoute?.path);
    }

    async authNavigationGuard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        try {
            if (this.noAuth(to)) {
                return true;
            }

            const currentUser = await this.authClient.currentUser;
            if (currentUser.isAuthenticated) {
                return true;
            }

            const auth = await currentUser.freshAuth;
            if (auth) {
                return true;
            }
        } catch (e) {
            console.info(`Authorization failed for route: ${to.path}:${(e as any).message}`);
            return {
                ...this.loginRoute,
                query: {
                    error: (e as any).name,
                    error_description: (e as any).message
                }
            };
        }

        return this.loginRoute;
    }

    doLogin() {
        if (this.loginRoute && this.router.currentRoute.value.path !== this.loginRoute.path) {
            this.router.push(this.loginRoute);
        }
    }
}

/*
Adds a Navigation Guard to the Vue Router, routes to login if not authrorized for the route.

Contains a showLogin method and a onLogin method that use the router to
navigate to the correct routes.
 */
export class AuthRouterVue {
    authClient: AuthClient;
    defaultPath: string;
    vueRouter: Router;
    routeAfterLogin?: RouteLocationNormalized;

    apps: Record<string, AppAuthRouterVue> = {};

    constructor({ authClient, router, defaultPath }: AuthRouterConstructorArgs) {
        this.authClient = authClient;

        this.defaultPath = defaultPath ?? '/';
        this.vueRouter = router;

        authClient.use({
            loginHandler: this.doLogin.bind(this)
        });
        authClient.on('login', this.onLogin.bind(this));
        authClient.on('logout', this.onLogout.bind(this));

        router.beforeEach(async (to, from) => this.checkAuthMiddleware(to, from));
    }

    mountAt(path: string, loginComponent: Component) {
        this.apps[path] = new AppAuthRouterVue(path, this.authClient, loginComponent, this.vueRouter);
        return this.apps[path];
    }

    isLoginRoute(r: RouteLocationNormalized) {
        return _.some(this.apps, a => r.name === a.loginRoute.name);
    }

    getAppForPath(path: string) {
        return _(this.apps).values().find(a => path.startsWith(a.rootPath));
    }

    async checkAuthMiddleware(to: RouteLocationNormalized, from: RouteLocationNormalized) {
        if (!this.isLoginRoute(to)) {
            this.routeAfterLogin = to;
        }

        const appRouter = this.getAppForPath(to.path);

        if (!appRouter) {
            return true;
        }

        return appRouter.authNavigationGuard(to, from);
    }

    async doLogin() {
        await this.vueRouter.isReady();
        const router = this.getAppForPath(this.vueRouter.currentRoute.value.path) ?? _(this.apps).values().first();
        router?.doLogin();
    }

    onLogin() {
        // If login was invoked because of a freshAuth exception, or nav to a guarded route,
        // this will be set.
        if (this.routeAfterLogin) {
            return this.vueRouter.replace({ ...this.routeAfterLogin });
        }

        // No route has been specified so redirect to the current app's
        // root
        const appName = this.vueRouter.currentRoute.value.path.split('/')[1];
        if (['hq', 'deployment', 'diagnostics', 'dashboard'].includes(appName)) {
            this.vueRouter.replace(`/${appName}`);
        } else {
            this.vueRouter.replace(this.defaultPath);
        }
    }

    onLogout() {
        this.doLogin();
    }
}
