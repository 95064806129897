import type { Feature, FeatureCollection, MultiPolygon, Polygon, Position } from 'geojson';
import _ from 'lodash';
import mapboxgl, { LngLatBounds } from 'mapbox-gl';
import type { AnyLayer, GeoJSONSource, Layer, Map } from 'mapbox-gl';
import Compare from 'mapbox-gl-compare';
import { EMPTY_GEOJSON } from './constants';
import type { MapSource } from './types';

type Point = {
    lat: number;
    lng: number;
};

export function updateLayer(map: Map, layer: Layer) {
    if (!map.getLayer(layer.id)) {
        if (!map.getSource(layer.source as string)) {
            map.addSource(layer.source as string, { type: 'geojson', data: EMPTY_GEOJSON });
        }
        map.addLayer(layer as AnyLayer);
        return;
    }

    _.forEach(layer.paint, (value, key) => {
        map.setPaintProperty(layer.id, key, value);
    });

    _.forEach(layer.layout, (value, key) => {
        map.setLayoutProperty(layer.id, key, value);
    });
}

export function updateSource(map: Map, { id, data }: MapSource) {
    if (!map.getSource(id)) {
        map.addSource(id, { type: 'geojson', data });
        return;
    }

    const source = map.getSource(id) as GeoJSONSource;

    source.setData(data);
}
export function getBbox(coords: Position[] | [number, number][]): LngLatBounds {
    return (coords as [number, number][]).reduce(
        (bounds, coord) => bounds.extend(coord),
        new LngLatBounds(),
    );
}

function featureToCoordinates(feature: Feature<MultiPolygon | Polygon>): Position[] {
    return feature.geometry.type === 'MultiPolygon'
        ? feature.geometry.coordinates.flatMap((polygon) => polygon[0])
        : feature.geometry.coordinates[0];
}

export function getFeatureCollectionBbox(
    collection: FeatureCollection<MultiPolygon | Polygon>,
): LngLatBounds {
    return getBbox(collection.features.flatMap(feature => featureToCoordinates(feature)));
}

export function getFeatureBbox(feature: Feature<MultiPolygon | Polygon>): LngLatBounds {
    return getBbox(featureToCoordinates(feature));
}

function getBboxAnchor(bbox: LngLatBounds, orientation = 'north'): Point {
    let lat: number;
    let lng: number;
    if (orientation === 'north') {
        lat = bbox.getNorth();
        lng = bbox.getCenter().lng;
    } else if (orientation === 'south') {
        lat = bbox.getSouth();
        lng = bbox.getCenter().lng;
    } else if (orientation === 'west') {
        lat = bbox.getCenter().lat;
        lng = bbox.getWest();
    } else if (orientation === 'east') {
        lat = bbox.getCenter().lat;
        lng = bbox.getEast();
    } else {
        lat = bbox.getCenter().lat;
        lng = bbox.getCenter().lng;
    }
    return {
        lat,
        lng
    };
}

export function getClosestPointBiasedNorth(
    anchorPoint: mapboxgl.Point,
    featurePoints: mapboxgl.Point[],
    bias = 0,
): [number, number] {
    const closestPoint = _.minBy(
        featurePoints,
        d => Math.sqrt(
            (anchorPoint.y - d.y) ** 2
            + (anchorPoint.x - d.x) ** 2
        ) + (anchorPoint.y - d.y) ** 2
            * bias
    ) as mapboxgl.Point;
    return [closestPoint.x, closestPoint.y];
}

export function getCenter(coords: [number, number][]): Point {
    return getBboxAnchor(getBbox(coords));
}

export function getAnchor(coords: Position[] | [number, number][], orientation?: string): Point {
    return getBboxAnchor(getBbox(coords), orientation);
}

export const WORLD_SIZED_RECTANGLE_COORDS: Position[] = [
    [-180, -90],
    [-180, 90],
    [180, 90],
    [180, -90],
    [-180, -90],
];

export function getSurroundingAreaFeature(
    boundaryGeometry: MultiPolygon | Polygon | undefined,
): Feature<MultiPolygon | Polygon> {
    if (boundaryGeometry === undefined) {
        return {
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [WORLD_SIZED_RECTANGLE_COORDS],
            },
            properties: null,
        };
    }

    if (boundaryGeometry.type === 'Polygon') {
        const [area, ...holes] = boundaryGeometry.coordinates;
        const geometry = (holes.length > 0)
            ? {
                type: 'MultiPolygon' as const,
                coordinates: [
                    [WORLD_SIZED_RECTANGLE_COORDS, area],
                    ...holes.map((hole) => [hole]),
                ],
            }
            : {
                type: 'Polygon' as const,
                coordinates: [WORLD_SIZED_RECTANGLE_COORDS, area],
            };
        return {
            type: 'Feature',
            geometry,
            properties: null,
        };
    }

    const areas = boundaryGeometry.coordinates.map((polygon) => polygon[0]);
    const holes = boundaryGeometry.coordinates
        .map((polygon) => polygon.slice(1))
        .filter((hole) => hole.length > 0);
    return {
        type: 'Feature',
        geometry: {
            type: 'MultiPolygon' as const,
            coordinates: [[WORLD_SIZED_RECTANGLE_COORDS, ...areas], ...holes],
        },
        properties: null,
    };
}

export type CompareOptions = {
    orientation?: 'vertical' | 'horizontal',
    mousemove?: boolean
}

export function createComparisonMap(beforeMap: Map, afterMap: Map, comparisonContainer: HTMLElement, options: CompareOptions) {
    const compare = new Compare(beforeMap, afterMap, comparisonContainer, options);
    const oldOnMove = compare._onMove;
    compare._onMove = (function (this: Compare, e) {
        oldOnMove(e);
        this.fire('slide', { currentPosition: this.currentPosition });
    }).bind(compare);

    return compare;
}
