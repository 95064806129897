<template>
    <form
        class="login-form flex flex-col w-full items-center"
        spellcheck="false"
        @submit.prevent=""
    >
        <fieldset
            class="flex flex-col w-full items-center border-none mb-8"
            :disabled="loading"
        >
            <!-- Name and id fields set for password managers -->
            <input
                id="username"
                ref="username"
                v-model="formValues.username"
                autocomplete="off"
                class="login-input"
                name="username"
                placeholder="username"
            >
        </fieldset>
        <button
            class="button"
            tabindex="-1"
            @click="emit('request-code');"
        >
            Send Reset Code
        </button>
        <div
            class="text-button mt-2"
            @click="emit('back-to-login')"
        >
            Back to Login
        </div>
    </form>
</template>

<style lang="postcss" src="./common.pcss" scoped></style>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import type { PropType } from 'vue';
import type { AuthUiState } from './authenticationHelpers';

defineProps({
    formValues: {
        type: Object as PropType<AuthUiState<'request-code'>['formValues']>,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    }
});

const emit = defineEmits<{
    (e: 'back-to-login'): void,
    (e: 'request-code'): void,
}>();

const username = ref<HTMLInputElement | null>(null);

onMounted(async () => {
    await nextTick();
    (username.value as HTMLInputElement).focus();
});
</script>
