import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { GraphQLResponse } from 'graphql-request/dist/types';
import _ from 'lodash';
import { DesGraphQLClient, createGraphqlClient } from '@api-client/graphql-client';
import type { App, AppName } from '@api-client/types';
import { RegionApiClient, TenantApiResult } from './RegionApiClient';
import { TenantApiClient } from './TenantApiClient';
import { AuthClient, authClientSingleton } from './auth';
import { env } from './envVars';
import { DynamicQuery } from './helpers/types';

export type { TenantApiResult };

export type PageLink = {
    name: string;
    label: string;
    url?: string;
    sameApp?: boolean;
    icon?: string;
    disabled?: boolean;
    children?: PageLink[];
};

type ApiClientOptions = {
    forceHttps?: boolean;
}

export class RootApiClient {
    Auth: AuthClient = authClientSingleton;

    options?: ApiClientOptions;

    // The region hosting the front-end we're currently querying from
    primaryRegion: RegionApiClient;
    // Additional regions to query from
    additionalRegions: RegionApiClient[];

    private regionApiClientByApiHost: Record<string, RegionApiClient>;

    constructor(options?: ApiClientOptions) {
        this.options = options;
        this.primaryRegion = new RegionApiClient(this.options);

        const alternateHosts = (env.ALTERNATE_API_HOSTS ?? '')
            .split(',')
            .filter(apiHost => !!apiHost);

        this.additionalRegions = alternateHosts.map(apiHost => new RegionApiClient({
            ...this.options,
            apiHost
        }));

        this.regionApiClientByApiHost = {
            [this.primaryRegion.apiHost]: this.primaryRegion,
            ..._(this.additionalRegions)
                .keyBy(r => r.apiHost)
                .value()
        };
    }

    get appConfigs() {
        return this.primaryRegion.appConfigs;
    }
    async userIsAdmin() {
        const user = await this.Auth.currentUser;
        return user && user?.groups?.includes('org:darkhorse');
    }

    async listApps(tenant?: string): Promise<App[]> {
        return this.queryList(region => region.listApps(tenant));
    }
    async getApp(tenant: string, appName: string): Promise<App> {
        const { result: app } = await this.querySingleResult(region => region.getApp(tenant, appName));
        if (app) {
            return app;
        }
        throw new Error(`App: ${appName} for tenant: ${tenant} does not exist`);
    }
    async listApis() {
        return this.queryList(region => region.listApis());
    }
    async listApisForApp(appName: AppName, includeUnavailable = false) {
        return this.queryList(region => region.listApisForApp(appName, includeUnavailable));
    }
    async getApi(tenant: string): Promise<TenantApiResult> {
        const { result: api } = await this.querySingleResult(region => region.getApi(tenant));
        if (api) {
            return api;
        }
        throw new Error(`No API for tenant: ${tenant}`);
    }

    async getTenantApiClient(tenant: string): Promise<TenantApiClient> {
        const api = await this.getApi(tenant);
        return new TenantApiClient(api, this.getGraphqlClient(api));
    }
    getGraphqlClient(api: TenantApiResult): DesGraphQLClient {
        return createGraphqlClient(api.graphql.url.host);
    }

    async querySingleRegion<ReturnType = any, Variables extends Record<string, any> = Record<string, any>>(
        regionApiHost: string,
        query: TypedDocumentNode<ReturnType, Variables> | DynamicQuery<Variables, ReturnType>,
        variables?: Variables
    ): Promise<GraphQLResponse<ReturnType>> {
        return this.regionApiClientByApiHost[regionApiHost].queryFullResponse(
            query,
            variables
        );
    }

    /**
     * Run a query across all regions, returning the first non-null result.
     *
     * Use this if you know a result should only appear in one region, but
     * aren't sure which region. For example, if all you have is a tenant key
     * (such as "tfs"), this will allow you to query ca-central-1 and us-east-1
     * simultaneously and return the right one
     *
     * @param regionQuery A query to run once per region
     * @returns The first defined result returned by any region
     */
    private async querySingleResult<T>(
        regionQuery: (region: RegionApiClient) => Promise<T | undefined>
    ): Promise<{ region: RegionApiClient; result: T | undefined }> {
        const results = await Promise.all([
            regionQuery(this.primaryRegion),
            ...this.additionalRegions.map(c => regionQuery(c))
        ]);

        const resultIndex = _.findIndex(results, a => !!a);
        const result = results[resultIndex];
        const region = resultIndex === 0
            ? this.primaryRegion
            : this.additionalRegions[resultIndex - 1];
        return { result, region };
    }

    /**
     * Run a query across all regions, aggregating the results into a single list
     *
     * Use this to generate the full list of all results regardless of where they
     * live, as if all tenants were hosted in the same region
     *
     * @param regionQuery A query to run once per region
     * @returns A list containing all results from all regions
     */
    private async queryList<T>(
        regionQuery: (region: RegionApiClient) => Promise<T[]>
    ): Promise<T[]> {
        const results = await Promise.all([
            regionQuery(this.primaryRegion),
            ...this.additionalRegions.map(c => regionQuery(c)),
        ]);
        return _.flatten(results);
    }

    async authenticate() {
        await Promise.all([
            this.primaryRegion.authenticate(),
            ...this.additionalRegions.map(r => r.authenticate())
        ]);
    }

    async getUserHash() {
        return this.primaryRegion.getUserHash();
    }
}
