import { reactive } from 'vue';
import { AuthChallenge, passwordChallenge } from '@api-client/auth';
import { AuthUiState, authClientSideValidationOk, getUserFacingMsgFromCognitoError } from '../authenticationHelpers';
import { BaseUiState } from './baseAuthState';
import type { ConstructorArgs, LoginFlow } from './loginFlow';

export class ResetPasswordUiState extends BaseUiState<'reset-password'> {
    component = 'reset-password' as const;
    formValues: AuthUiState<'reset-password'>['formValues'] = reactive({
        username: '',
        password: '',
        remember: false,
        resetCode: '',
        verify: ''
    });
    readonly options: AuthUiState<'reset-password'>['options'] = {
        hasResetCode: true
    };

    authChallenge: AuthChallenge;
    constructor(loginFlow: LoginFlow, authChallenge: AuthChallenge, args?: ConstructorArgs<'reset-password'>) {
        super(loginFlow, args);
        this.authChallenge = authChallenge;

        if (args?.formValues) {
            if (args.formValues.username !== undefined) {
                this.formValues.username = args.formValues.username;
            }
            if (args.formValues.password !== undefined) {
                this.formValues.password = args.formValues.password;
            }
            if (args.formValues.remember !== undefined) {
                this.formValues.remember = args.formValues.remember;
            }
            if (args.formValues.resetCode !== undefined) {
                this.formValues.resetCode = args.formValues.resetCode;
            }
            if (args.formValues.verify !== undefined) {
                this.formValues.verify = args.formValues.verify;
            }
        }

        if (args?.options) {
            if (args.options.hasResetCode !== undefined) {
                this.options.hasResetCode = args.options.hasResetCode;
            }
        }
    }

    async handleEvent(event: 'request-new-code' | 'change-password' | 'back-to-login') {
        if (event === 'request-new-code') {
            await this.onRequestCode();
        } else if (event === 'back-to-login') {
            this.backToLogin();
        } else if (event === 'change-password') {
            await this.changePassword();
        }
    }

    async onRequestCode() {
        this.prompt = `Sending Reset Request to: ${this.formValues.username}`;
        this.error = undefined;
        try {
            this.loading = true;
            const challenge = await passwordChallenge({ username: this.formValues.username }, true);
            this.loginFlow.transitionTo('reset-password', challenge, {
                formValues: this.formValues,
                options: { hasResetCode: true },
                prompt: challenge.prompt
            });
        } catch (err: any) {
            this.prompt = undefined;
            this.error = 'Request failed. Please try again.';
            throw new Error(err.message);
        } finally {
            this.loading = false;
        }
    }

    async changePassword() {
        const trimmedFormValues = {
            password: this.formValues.password.trim(),
            verify: this.formValues.verify.trim(),
            resetCode: this.options.hasResetCode ? this.formValues.resetCode.trim() : undefined
        };
        const validationResult = authClientSideValidationOk({
            passwordField1: trimmedFormValues.password,
            passwordField2: trimmedFormValues.verify,
            resetCode: trimmedFormValues.resetCode
        });

        if (!validationResult.ok) {
            this.error = validationResult.errorMsg;
            return;
        }

        try {
            this.loading = true;
            await this.authChallenge.response(trimmedFormValues);
            this.loginFlow.transitionTo('login', {
                formValues: this.formValues,
                prompt: 'Password Changed Successfully. Please log in with your new password.'
            });
        } catch (err: any) {
            this.error = getUserFacingMsgFromCognitoError(err.message);
            throw new Error(err.message);
        } finally {
            this.loading = false;
        }
    }

    backToLogin() {
        this.loginFlow.transitionTo('login', {
            formValues: this.formValues
        });
    }
}
