<template>
    <div
        class="background flex fixed left-0 top-0 w-screen h-screen bg-white justify-center
        items-center z-50"
    >
        <LoginForm :model-value="currentUiState" />
    </div>
</template>

<script setup lang="ts">
import LoginForm from '@/views/LoginForm';
import { useLoginFlow } from '@/views/LoginForm/useLoginFlow';

const { currentUiState } = useLoginFlow();
</script>
