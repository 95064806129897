import * as t from 'io-ts';
import { createDynamicQuery, createParseFunction } from '@api-client/helpers/types';

const perfDiagValueResult = t.type({
    api: t.type({
        perfdiags: t.union([t.null, t.type({
            value: t.number
        })])
    })
});

export const perfdiagValueQuery = createDynamicQuery(
    (variables?: {
        tenantId: string,
        filters: any[],
        property: string
    }) => {
        const {
            tenantId,
            property,
            filters
        } = variables!;

        const query = `
            query ($tenantId: String! $filters: JSON) {
            api: getTenantApi(name: $tenantId) {
                perfdiags(
                    filter: {
                        and: $filters
                    }
                ) {
                    value: ${property}
                }
            }
        }`;
        const queryVariables = {
            tenantId,
            filters: JSON.stringify(filters)
        };

        return { query, queryVariables };
    },
    createParseFunction(perfDiagValueResult)
);
