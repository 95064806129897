import _ from 'lodash';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { redirectToValidRouteForUser } from '@/router/helpers/redirectToValidRouteForUser';
import { route as dashboardRoute } from './dashboard';
import { route as deploymentRoute } from './deployment';
import { route as deploymentAuditorRoute } from './deploymentAuditor';
import { route as diagnosticsRoute } from './diagnostics';
import { route as hqRoute } from './hq';
import { route as riskRoute } from './risk';

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            redirect: '/hq',
            beforeEnter: redirectToValidRouteForUser({ appName: 'Home', allowOtherApps: true })
        },
        hqRoute,
        deploymentRoute,
        diagnosticsRoute,
        dashboardRoute,
        riskRoute,
        deploymentAuditorRoute
    ],
    strict: false
});

// Adapted from the approach described below:
// https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
router.afterEach(async (to, from) => {
    // Wait for navigation to settle before changing the title
    await nextTick();

    // Match the most-specific route that has a meta.title
    // This is so we can have an app-level title (DHE Home),
    // but a page within that app can override (DHE Home Settings, for example)
    const title = _.findLast(to.matched, r => !!r.meta.title)?.meta.title;
    if (title && typeof title === 'string') {
        document.title = title;
    }
});

(window as any).router = router;

export default router;
