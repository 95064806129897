import { Ref, shallowRef } from 'vue';
import { AnyAuthUiState, AuthComponentName, AuthUiState } from '../authenticationHelpers';
import { RequestCodeUiState } from './requestCode';
import { ResetPasswordUiState } from './resetPassword';
import { SsoPasswordStepUiState } from './ssoPasswordStep';
import { SsoUserStepUiState } from './ssoUserStep';

export type ConstructorArgs<ComponentName extends AuthComponentName> = {
    formValues?: Partial<AuthUiState<ComponentName>['formValues']>;
    options?: Partial<AuthUiState<ComponentName>['options']>;
    prompt?: string;
}

type WithoutFirst<T extends Array<any>> = T extends [infer First, ...(infer R)]
    ? R
    : T;

type ConstructorArgLookup = {
    login: ConstructorParameters<typeof SsoUserStepUiState>;
    password: ConstructorParameters<typeof SsoPasswordStepUiState>;
    'request-code': ConstructorParameters<typeof RequestCodeUiState>;
    'reset-password': ConstructorParameters<typeof ResetPasswordUiState>;
}

export class LoginFlow {
    currentState: Ref<AnyAuthUiState>;
    components = {
        login: SsoUserStepUiState,
        password: SsoPasswordStepUiState,
        'request-code': RequestCodeUiState,
        'reset-password': ResetPasswordUiState
    };

    constructor() {
        // eslint-disable-next-line new-cap
        this.currentState = shallowRef(new this.components.login(this));
    }

    transitionTo<T extends keyof LoginFlow['components']>(
        name: T,
        ...args: WithoutFirst<ConstructorArgLookup[T]>
    ): void {
        // TS can't narrow the args type based on the generic parameter
        // @ts-expect-error
        this.currentState.value = new this.components[name](this, ...args);
    }
}
