import mixpanel from 'mixpanel-browser';

interface Tracker {
    /**
     * Register some session-level properties so they don't need to
     * manually be provided to every event.
     *
     * @param props Properties that will be provided with every event
     */
    registerSessionProps(props: Record<string, any>): void;
    /**
     * Identify the current user. Call this after logging in.
     *
     * @param id An id that uniquely identifies the current user
     * @param idProps A set of other properties belonging to the current user
     */
    identify(id: string, idProps?: Record<string, any>): void;
    /**
     * Record an event that occurred.
     *
     * @param event The name of the event
     * @param props Additional properties of the event
     */
    track(event: string, props?: Record<string, any>): void;
    /**
     * Start a timer for an event. The time between this call
     * and the following call to track() for the same event will
     * be recorded in the event's Duration property
     * @param event The name of the event
     */
    timeEvent(event: string): void;
}

class MixpanelTracker implements Tracker {
    private sessionProps: Record<string, any> = {};
    private mixpanel = mixpanel;

    constructor(token: string) {
        // Mixpanel docs say that calling this with just the token is ok,
        // but the exported TS type expects all 3 args
        // See: https://www.npmjs.com/package/mixpanel-browser
        // @ts-ignore
        this.mixpanel.init(token);
    }

    registerSessionProps(props: Record<string, any>) {
        this.sessionProps = {
            ...this.sessionProps,
            ...props
        };
    }
    identify(id: string, idProps?: Record<string, any>) {
        this.mixpanel.identify(id);
        if (idProps) {
            this.mixpanel.people.set(idProps);
        }
    }
    track(event: string, props?: Record<string, any>) {
        this.mixpanel.track(event, {
            ...this.sessionProps,
            ...props
        });
    }
    timeEvent(event: string) {
        this.mixpanel.time_event(event);
    }
}

/* eslint-disable no-empty-function, @typescript-eslint/no-empty-function */
class EmptyTracker implements Tracker {
    registerSessionProps() {}
    track() {}
    identify() {}
    timeEvent() {}
}
/* eslint-enable no-empty-function, @typescript-eslint/no-empty-function */

/**
 * Container class that allows us to swap in the mixpanel implementation
 * at runtime
 */
class TrackerContainer implements Tracker {
    private impl: Tracker;

    constructor() {
        this.impl = new EmptyTracker();
    }
    initMixpanel(token: string) {
        this.impl = new MixpanelTracker(token);
    }
    registerSessionProps(props: Record<string, any>): void {
        return this.impl.registerSessionProps(props);
    }
    identify(id: string, idProps?: Record<string, any>): void {
        return this.impl.identify(id, idProps);
    }
    track(event: string, props?: Record<string, any>): void {
        return this.impl.track(event, props);
    }
    timeEvent(event: string): void {
        return this.impl.timeEvent(event);
    }
}

export const tracker = new TrackerContainer();
