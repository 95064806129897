<template>
    <form
        class="login-form flex flex-col w-full items-center"
        spellcheck="false"
        @submit.prevent=""
    >
        <fieldset
            class="flex flex-col w-full items-center border-none mb-8"
            :disabled="loading"
        >
            <div
                v-if="hasResetCode"
                class="flex gap-2 justify-center items-center"
            >
                <input
                    ref="resetCode"
                    v-model="formValues.resetCode"
                    autocomplete="off"
                    class="login-input"
                    placeholder="reset code"
                >
                <div
                    class="text-button min-w-32 h-full"
                    tabindex="-1"
                    @click="emit('request-new-code')"
                >
                    Request New Code
                </div>
            </div>
            <div class="pt-4 w-full">
                Enter a new password <span class="text-[14px]">(minimum 14 characters)</span>
            </div>
            <input
                ref="password"
                v-model="formValues.password"
                autocomplete="off"
                class="login-input"
                placeholder="new password"
                type="password"
            >
            <input
                v-model="formValues.verify"
                autocomplete="off"
                class="login-input"
                placeholder="re-type password"
                type="password"
            >
        </fieldset>
        <button
            class="button"
            tabindex="-1"
            @click="emit('change-password')"
        >
            Change Password
        </button>
        <div
            class="text-button mt-2"
            @click="emit('back-to-login')"
        >
            Back to Login
        </div>
    </form>
</template>

<style lang="postcss" src="./common.pcss" scoped></style>

<script setup lang="ts">
import { nextTick, onMounted, ref } from 'vue';
import type { PropType } from 'vue';
import type { AuthUiState } from './authenticationHelpers';

const props = defineProps({
    formValues: {
        type: Object as PropType<AuthUiState<'reset-password'>['formValues']>,
        required: true,
    },
    hasResetCode: {
        type: Boolean,
        required: true
    },
    loading: {
        type: Boolean,
        required: true,
    }
});

const emit = defineEmits<{
    (e: 'request-new-code'): void,
    (e: 'change-password'): void,
    (e: 'back-to-login'): void
}>();

const resetCode = ref<HTMLInputElement | null>(null);

onMounted(async () => {
    await nextTick();
    if (props.hasResetCode) {
        (resetCode.value as HTMLInputElement).focus();
    }
});
</script>
