import * as d3 from 'd3';

const filters: Record<string, any> = {
    int: (value: any) => parseInt(value, 10),
    thousands: (value: any) => {
        if (!Number.isFinite(value)) {
            return '-';
        }
        let prefix = '';
        if (value < 0) {
            prefix = '-';
        }
        const digits = (`${Math.abs(value)}`).split('');
        const n = digits.length - 1;
        let str = '';
        for (let i = 0; i <= n; ++i) {
            if (i !== 0 && !(i % 3)) {
                str = `,${str}`;
            }
            str = digits[n - i] + str;
        }
        return prefix + str;
    },
    percentage: (value: any) => `${(value * 100).toFixed(0)}%`,
    time: (value: any) => {
        if (!value || !Number.isFinite(value)) {
            return '-';
        }
        let prefix = '';
        if (value < 0) {
            prefix = '-';
        }
        const minutes = Math.floor(Math.abs(value) / 60);
        const seconds = Math.floor(Math.abs(value) % 60);
        return `${prefix}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    seconds: (value: any) => {
        if (!value || !Number.isFinite(value)) {
            return '-';
        }
        return `${filters.int(value)}`;
    },
    variable: (value: any, format: string) => {
        if (value === -1) {
            return '-';
        }
        const requestedFilters = format.split('|');
        for (let i = 0; i < requestedFilters.length; ++i) {
            value = filters[requestedFilters[i]](value);
        }
        return value;
    },
    month: (date: Date, short: boolean) => {
        // let months = [
        //     'January',
        //     'February',
        //     'March',
        //     'April',
        //     'May',
        //     'June',
        //     'July',
        //     'August',
        //     'September',
        //     'October',
        //     'November',
        //     'December'
        // ];
        // return months[month];
        const month = d3.utcFormat('%B')(date);
        return (short) ? month[0] : month;
    },
    quarter: (date: Date, short: boolean) => {
        if (!date) {
            return '-';
        }
        const month = date.getUTCMonth();
        const quarter = month && Math.floor((month / 12) * 4);
        if (short) {
            if (month % 3 !== 0) {
                return '';
            }
            const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
            return quarters[quarter];
        }
        // let quarters = ['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter'];
        const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
        return quarters[quarter];
    },
    dateTitle: (date: Date, grouping: any, short: boolean) => {
        if (!date) {
            return '-';
        }
        const groupingFilter = filters[grouping] || filters.month;
        const fmtedMth = groupingFilter(date, short);
        if (short) {
            return fmtedMth;
        }
        return `${fmtedMth} ${date.getUTCFullYear()}`;
    },
    reverse: (value: any[]) => {
        if (!(value instanceof Array)) {
            return value;
        }
        return value.slice().reverse();
    }
};

export default filters;
