import { isNotNil } from 'common';
import { GraphQLResponse } from 'graphql-request/dist/types';
import _ from 'lodash';
import {
    GetAppDocument,
    GetGraphqlUriDocument,
    ListApisDocument,
    ListAppsDocument
} from '@/generated/graphql-operations';
import { DesGraphQLClient, createGraphqlClient } from '@api-client/graphql-client';
// eslint-disable-next-line
import { TypedDocumentNode, ResultOf } from "@graphql-typed-document-node/core";

import { App, AppName, NotNull } from '@api-client/types';
import { AuthClient, authClientSingleton } from './auth';
import {
    EnvConfig,
    getEnvConfig,
} from './config';
import { DynamicQuery } from './helpers/types';

export type TenantApiResult = NotNull<ResultOf<typeof GetGraphqlUriDocument>['api']>;

type RegionApiClientOptions = {
    forceHttps?: boolean;
    apiHost?: string;
}

export class RegionApiClient {
    Auth: AuthClient = authClientSingleton;
    forceHttps: boolean;
    graphqlClient: DesGraphQLClient;
    envConfig: EnvConfig;

    constructor(options?: RegionApiClientOptions) {
        this.envConfig = getEnvConfig({
            apiHost: options?.apiHost
        });

        this.graphqlClient = createGraphqlClient(this.envConfig.apiHost);
        this.forceHttps = options?.forceHttps ?? this.envConfig.apiIsDeployed;
    }

    get appConfigs() {
        return this.envConfig.appConfigs;
    }
    get apiHost() {
        return this.envConfig.apiHost;
    }

    async listApps(tenant?: string): Promise<App[]> {
        if (!tenant) {
            return _(this.appConfigs)
                .values()
                .map(cfg => ({ ...cfg, available: true }))
                .value();
        }
        const { api } = await this.query(
            ListAppsDocument,
            { tenant }
        );
        if (!api) {
            return [];
        }
        return api.listApps.map(app => {
            const {
                url,
                ...config
            } = this.appConfigs[app.name as AppName];

            return ({
                available: app.available,
                url: `${url}/${tenant}`,
                ...config,
            });
        });
    }

    async getApp(tenant: string, appName: string): Promise<App | undefined> {
        const { api } = await this.query(
            GetAppDocument,
            { tenant, app: appName }
        );

        if (!api || !api.app) {
            return undefined;
        }

        const app = api.app;

        const {
            url,
            ...config
        } = this.appConfigs[app.name as AppName];
        return ({
            available: app.available,
            url: `${url}/${tenant}`,
            ...config,
        });
    }
    async listApis() {
        const { apis } = await this.query(ListApisDocument);

        return apis
            .filter(isNotNil)
            .filter((api) => api.available)
            .map((api) => ({
                ...api,
                logo: this.resolveLogoUrl(api.logo)
            }));
    }
    async listApisForApp(appName: AppName, includeUnavailable = false) {
        const { apis } = await this.query(ListApisDocument);
        return apis
            .filter(isNotNil)
            .filter((api) => {
                const app = api.listApps.find(a => a.name === appName);
                return app && (includeUnavailable || app.available);
            })
            .map((api) => ({
                ...api,
                logo: this.resolveLogoUrl(api.logo)
            }));
    }

    async query<ReturnType = any, Variables extends Record<string, any> = Record<string, any>>(
        query: TypedDocumentNode<ReturnType, Variables> | DynamicQuery<Variables, ReturnType>,
        variables?: Variables
    ): Promise<ReturnType> {
        const { data, errors } = await this.pollUntilReady<GraphQLResponse<ReturnType>>(() => this.graphqlClient.request<ReturnType, Variables>(
            query,
            variables
        ));

        if (errors) {
            throw new Error(errors.map(e => e.message).join('\n'));
        }
        if (!data) {
            throw new Error('No data returned from query');
        }
        return data;
    }

    async queryFullResponse<ReturnType = any, Variables extends Record<string, any> = Record<string, any>>(
        query: TypedDocumentNode<ReturnType, Variables> | DynamicQuery<Variables, ReturnType>,
        variables?: Variables
    ): Promise<GraphQLResponse<ReturnType>> {
        return this.pollUntilReady<GraphQLResponse<ReturnType>>(() => this.graphqlClient.request<ReturnType, Variables>(
            query,
            variables
        ));
    }

    async getApi(tenant: string): Promise<TenantApiResult | undefined> {
        await this.authenticate();
        const { api } = await this.query(
            GetGraphqlUriDocument,
            { tenant }
        );
        if (!api) {
            return undefined;
        }
        if (this.forceHttps) {
            return {
                ...api,
                graphql: {
                    ...api.graphql,
                    url: {
                        ...api.graphql.url,
                        uri: api.graphql.url.uri.replace('http:', 'https:'),
                        host: api.graphql.url.host.replace('http:', 'https:'),
                    }
                },
                logo: this.resolveLogoUrl(api.logo)
            };
        }
        return {
            ...api,
            logo: this.resolveLogoUrl(api.logo)
        };
    }
    private async pollUntilReady<T>(cb: () => Promise<T>, timeout = 3000): Promise<T> {
        const result = await cb();
        if ((result as any).ready === false) {
            return new Promise(resolve => {
                setTimeout(() => this.pollUntilReady(cb, timeout).then(resolve), timeout);
            });
        }
        return result;
    }

    private resolveLogoUrl(logo: string | null | undefined): string | null {
        return (typeof logo === 'string')
            ? `${this.envConfig.apiHost}/${logo}`
            : null;
    }

    async getUserHash() {
        const user = await this.Auth.currentUser;

        const result = await fetch(`${this.graphqlClient.host}/userHash`, {
            method: 'GET',
            // @ts-ignore
            withCredentials: true,
            credentials: 'include',
            headers: user.auth?.headers
        });
        if (result.ok) {
            return result.text();
        }
        throw new Error('Couldn\'t get user hash');
    }

    async authenticate() {
        const user = await this.Auth.currentUser;
        await fetch(`${this.graphqlClient.host}/authenticate`, {
            method: 'GET',
            // @ts-ignore
            withCredentials: true,
            credentials: 'include',
            headers: user.auth?.headersAccess
        });
    }
}
