import { Ref } from 'vue';

export type MaybeRef<T> = T | Ref<T>;

export function unwrap<T>(maybeRef: MaybeRef<T>): T {
    if (maybeRef && typeof maybeRef === 'object' && 'value' in maybeRef) {
        return maybeRef.value;
    }
    return maybeRef;
}
