import * as t from 'io-ts';

/*
 * These type definitions are necessary because the union behaviour we
 * want is not representable in GQL types. Essentially:
 *
 * If a Node's isTarget column is true, its type column must be defined
 * If not, it's optional
 *
 * GQL doesn't support literal types (i.e. isTarget: true instead of isTarget: boolean)
 */

export const targetNode = t.type({
    nodeId: t.number,
    hexId: t.number,
    type: t.string,
    lon: t.string,
    lat: t.string,
    isSource: t.boolean,
    isTarget: t.literal(true),
});

const nonTargetNode = t.type({
    nodeId: t.number,
    hexId: t.number,
    type: t.union([t.string, t.undefined]),
    lon: t.string,
    lat: t.string,
    isSource: t.boolean,
    isTarget: t.literal(false),
});

export const node = t.union([targetNode, nonTargetNode]);
