import { reactive } from 'vue';
import { AuthChallenge, authClientSingleton } from '@api-client/auth';
import { AuthUiState } from '../authenticationHelpers';
import { BaseUiState } from './baseAuthState';
import type { ConstructorArgs, LoginFlow } from './loginFlow';

export class SsoPasswordStepUiState extends BaseUiState<'login'> {
    component = 'login' as const;
    formValues: AuthUiState<'login'>['formValues'] = reactive({
        username: '',
        password: '',
        remember: false,
        resetCode: '',
        verify: ''
    });

    readonly options: AuthUiState<'login'>['options'] = {
        continueText: 'login',
        disableUsername: true,
        hidePassword: false,
        showBackButton: true
    };

    constructor(loginFlow: LoginFlow, args?: ConstructorArgs<'login'>) {
        super(loginFlow, args);
        if (args?.formValues) {
            if (args.formValues.username !== undefined) {
                this.formValues.username = args.formValues.username;
            }
            if (args.formValues.password !== undefined) {
                this.formValues.password = args.formValues.password;
            }
            if (args.formValues.remember !== undefined) {
                this.formValues.remember = args.formValues.remember;
            }
            if (args.formValues.resetCode !== undefined) {
                this.formValues.resetCode = args.formValues.resetCode;
            }
            if (args.formValues.verify !== undefined) {
                this.formValues.verify = args.formValues.verify;
            }
        }
    }

    async handleEvent(event: 'continue' | 'back' | 'forgot-password') {
        if (event === 'continue') {
            await this.onLogin();
        } else if (event === 'back') {
            this.loginFlow.transitionTo('login', {
                formValues: {
                    ...this.formValues,
                    password: ''
                }
            });
        } else if (event === 'forgot-password') {
            await this.forgotPassword();
        }
    }

    async onLogin() {
        try {
            this.loading = true;
            await authClientSingleton.login({
                username: this.formValues.username.trim(),
                password: this.formValues.password.trim(),
                remember: this.formValues.remember
            });
        } catch (err: any) {
            if (err instanceof AuthChallenge) {
                this.handleAuthChallenge(err);
                return;
            }
            this.error = 'Login failed. Please check your credentials and try again.';
        } finally {
            this.loading = false;
        }
    }

    async forgotPassword() {
        this.loginFlow.transitionTo('request-code', {
            formValues: this.formValues,
            prompt: 'Please enter your username'
        });
    }

    private handleAuthChallenge(challenge: AuthChallenge) {
        if (challenge.challenge.passwordReset || challenge.challenge.passwordResetRequired) {
            this.loginFlow.transitionTo('reset-password', challenge, {
                formValues: this.formValues,
                options: { hasResetCode: true }
            });
        } else if (challenge.challenge.newPasswordRequired) {
            this.loginFlow.transitionTo('reset-password', challenge, {
                formValues: this.formValues,
                options: { hasResetCode: false }
            });
        } else {
            throw new Error('unsupported auth challenge');
        }
    }
}
