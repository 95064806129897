/**
 * Return whether the specified password strings (the "enter password" and
 * "verify password" fields) satisfy client side validation
 *
 * If specified, resetCode is validated as well.
 */
export function authClientSideValidationOk(args: {
    passwordField1: string,
    passwordField2: string,
    resetCode?: string,
}): { ok: true } | { ok: false, errorMsg: string} {
    const { passwordField1, passwordField2, resetCode } = args;

    if (passwordField1.length < 14) {
        return {
            ok: false,
            errorMsg: 'Password must be at least 14 characters',
        };
    }

    if (passwordField1 !== passwordField2) {
        return {
            ok: false,
            errorMsg: 'Passwords must match',
        };
    }

    if (resetCode !== undefined && resetCode.length === 0) {
        return {
            ok: false,
            errorMsg: 'Reset Code must not be empty',
        };
    }

    return { ok: true };
}

export function getUserFacingMsgFromCognitoError(cognitoError: string): string {
    // This is the only error we're expecting since we validate password
    // and reset code formats and lengths prior to hitting Cognito
    if (cognitoError.match(/invalid verification code/i)) {
        return 'Reset Code is incorrect';
    }

    return cognitoError;
}

type AuthFormValues = {
    username: string;
    password: string;
    remember: boolean;
    verify: string;
    resetCode: string;
}

type AuthComponentPropsLookup = {
    login: {
        formValues: AuthFormValues;
        options: {
            disableUsername?: boolean;
            hidePassword?: boolean;
            showBackButton?: boolean;
            continueText?: string;
        };
        events: {
            back: void;
            continue: void;
            'forgot-password': void;
        }
    };
    'request-code': {
        formValues: AuthFormValues;
        options: Record<string, never>;
        events: {
            'request-code': void;
            'back-to-login': void;
        }
    };
    'reset-password': {
        formValues: AuthFormValues;
        options: {
            hasResetCode?: boolean;
        };
        events: {
            'request-new-code': void;
            'change-password': void;
            'back-to-login': void;
        };
    };
}

export type AuthComponentName = keyof AuthComponentPropsLookup;

// Interface that represents a single Auth State (i.e. component + configuration)
// Exported as an interface so we have the option of implementing it as a
// class.
export interface AuthUiState<Component extends AuthComponentName = AuthComponentName> {
    component: Component,
    formValues: AuthComponentPropsLookup[Component]['formValues'];
    options: AuthComponentPropsLookup[Component]['options'];
    loading?: boolean;
    error?: string;
    prompt?: string;

    // Todo: event arguments (currently none have any so we just drop args)
    handleEvent<E extends keyof AuthComponentPropsLookup[Component]['events']>(
        event: E
    ): Promise<void>;
}

// Union of all valid ComponentNames to clean up TS types a bit
export type AnyAuthUiState = AuthUiState<'login'>
| AuthUiState<'request-code'>
| AuthUiState<'reset-password'>;
